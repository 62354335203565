import React, { Component } from 'react';
import {Card, Elevation, Button, Icon, Intent, Dialog, Classes, Position,Toaster} from "@blueprintjs/core";
import { DateInput } from "@blueprintjs/datetime";
import CreatableSelect from 'react-select/lib/Creatable';
import { TimeHour, TimeMinute } from '../../core/components/data';
import {isString} from 'util';
import {Auth} from 'gg-react-utilities';
import moment from 'moment';
import defaultImage from "../../core/images/package_detail.jpg";
import {Helper} from 'gg-react-utilities';

export default class PackageDetails extends React.Component{
    
    constructor(props){
        super(props);

        this.state = {
            package_details:null,
            ModalInquiry: false,
            UserName:'',
            UserMobile:'',
            UserEmail:'',
            //date:new Date(),
            date: new Date(),
            UserHours:'',
            UserMinutes:'',
            sourceInquiryDetails:null
        }
        this.ModalInquiry = this.ModalInquiry.bind(this);
        this.closeModal = this.closeModal.bind(this);
        //console.log(this.props,"PackageDetails");
    }

    componentDidMount() {
        this.setState({
            package_details: this.props.package_details
        })
    }

    componentWillReceiveProps(nextProps) {

        this.setState({
            sourceInquiryDetails:nextProps.sourceInquiryDetails,
            date:'',
            UserHours:'',
            UserMinutes:'',
            ModalInquiry:false
        });

    }



    InquirySubmit = ()=>{

        const {UserName, UserMobile, UserEmail, date, UserHours, UserMinutes} = this.state;


        let message ='abc';
        if(UserName == ''){
            message = 'Enter your name!.';
        }else if(UserMobile == ''){
            message = 'Enter your mobile number!.';
        }else if(UserMobile.length != 10){
            message = 'Mobile number is 10 digit number!.';
        }else if(UserEmail == ''){
            message = 'Enter your email address!.';
        }else if(!UserEmail || !isString(UserEmail) || (!/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/g.test(UserEmail))) {
            message = 'Enter valid email address!.';
        }else if(date == ''){
            message = 'Select call response date!.';
        }else if(UserHours == ''){
            message = 'Select call response hours!.';
        }else if(UserMinutes == ''){
            message = 'Select call response Minute!.';
        }else{

            let UserDetail = Auth.user();

            let amount = this.state.package_details.select_price ? this.state.package_details.select_price : this.props.package_details.price_from
            let namount = amount ? amount : 0
      
            let sendData={
                package_id:this.props.package_details.package_id,
                package_name_slug:this.props.package_details.package_slug,
                package_name:this.props.package_details.package_name,
                
                //amount: this.state.package_details.select_price ? this.state.package_details.select_price : this.props.package_details.price_from,
                amount: namount,
                
                city_name:this.props.package_details.package_citie,
                day:this.props.package_details.day,
                night:this.props.package_details.night,
                //user_id:UserDetail.user_id,
                image_url:this.props.package_details.image ? this.props.package_details.image[0].BigImage : '',
                name:UserName,
                email:UserEmail,
                mobile:UserMobile,
                prefer_date:moment(date).format("MM/DD/YYYY"),
                prefer_hour:UserHours,
                prefer_minute:UserMinutes
            }

            this.props.setPackageEnquiry(sendData);

            return true;
        }
        Toaster.create({
            position: Position.RIGHT,
        }).show({message: message, intent: Intent.DANGER});
    }



    ModalInquiry() {

        if(!Auth.isLoggedIn()){

            Auth.login(true);
            return false;
        }
        let UserDetail = Auth.user();

        this.setState({
            UserName:UserDetail.fname,
            UserMobile:UserDetail.mobile,
            UserEmail:UserDetail.email,
            ModalInquiry: true
        });
    }
    closeModal() {this.setState({ModalInquiry: false});}
    handleDateChange = (date: Date | null) => this.setState({ date });
    handleFormatChange = (format: IDateFormatProps) => this.setState({ format });

    render(){
        return(
            <React.Fragment>
                <Card interactive={false} elevation={Elevation.TWO} className="col-12">
                    <h3>Package Details</h3>

                    <div className="col-12">
                      
                      {
                        this.state.package_details ?
                          <React.Fragment>
                            <div className="row">
                              <div className="d-flex">

                                <img
                                  src={this.state.package_details.image[0].BigImage}
                                  className="img-fluid hotel-img"
                                  onError={
                                      (e)=>{
                                          e.target.onerror = null; 
                                          e.target.src=defaultImage 
                                      }
                                  } 
                                  alt=""
                                />

                                <div className="ml-3">
                                  <h5>{this.state.package_details.package_name}</h5>
                                  <h6>{this.state.package_details.day} Days</h6>
                                </div>
                              </div>
                            </div>
                            <div className="row mt-4">
                                <div className="d-flex align-items-center w-100 details">
                                    <Icon icon="timeline-events" iconSize={20} />
                                    <div className="ml-3">
                                        <h6>DATE</h6>
                                        <p>Valid From {this.state.package_details.date}</p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center w-100 details">
                                    <Icon icon="office" iconSize={20} />
                                    <div className="ml-3">
                                        <h6>duration</h6>
                                        <p>{this.state.package_details.night} NIGHTS / {this.state.package_details.day} DAYS</p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center w-100 details">
                                    <Icon icon="time" iconSize={20} />
                                    <div className="ml-3">
                                        <h6>holiday type</h6>
                                        <p>{this.state.package_details.holiday_type}</p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center w-100 details">
                                    <Icon icon="map-marker" iconSize={20} />
                                    <div className="ml-3">
                                        <h6>country</h6>
                                        <p>{this.state.package_details.country_name}</p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center w-100 details">
                                    <Icon icon="map-marker" iconSize={20} />
                                    <div className="ml-3">
                                        <h6>cities</h6>
                                        <p>{this.state.package_details.package_citie}</p>
                                    </div>
                                </div>
                                <div className="w-100 details">
                                    <div className="d-flex align-items-center justify-content-between w-100">
                                        
                                      {
                                        this.state.package_details.price_from && this.state.package_details.price_from !=''  ?
                                        <React.Fragment>
                                          <h5>Starting From</h5>
                                          <h3>
                                            { 
                                              this.state.package_details.select_price ? 
                                                Helper.formatMoney(this.state.package_details.select_price) : Helper.formatMoney(this.state.package_details.price_from)
                                            }
                                          </h3>
                                        </React.Fragment>  
                                        :
                                        <React.Fragment>
                                          <h5></h5>
                                          <h3>Price on request</h3>
                                        </React.Fragment>
                                      }
                                      
                                    </div>
                                    <div className="d-flex justify-content-end w-100 mt-3">
                                        <span className="Insc">({this.state.package_details.note}) <Icon icon="info-sign" iconSize={14} className="ml-2" /></span>
                                    </div>
                                </div>
                                <div className="mt-4 w-100">
                                    <Button
                                        text="Enquire Now"
                                        intent={Intent.PRIMARY}
                                        onClick={this.ModalInquiry}
                                        className="d-block w-100 font-weight-light bp3-text-medium"
                                    />
                                </div>
                            </div>

                          </React.Fragment>
                        :
                        ''  
                      }
                    </div>
                </Card>

                <Dialog
                    title="Enquire Now"
                    className="bp3-dialog-large ModalInquiry"
                    isOpen={this.state.ModalInquiry}
                    onClose={this.closeModal}

                >
                    <div className={Classes.DIALOG_BODY}>
                       <div className="row">
                           <div className="col-12">
                               <div className="bp3-input-group">
                                   <input
                                       type="text"
                                       value={this.state.UserName}
                                       className="bp3-input"
                                       required
                                       onChange={(e)=>{
                                           this.setState({
                                               UserName:e.target.value
                                           })
                                       }}
                                   />
                                   <label className="form-control-placeholder">Name</label>
                               </div>
                               <div className="mt-4">
                                   <div className="bp3-input-group">
                                       <input
                                           type="number"
                                           value={this.state.UserMobile}
                                           className="bp3-input"
                                           required
                                           onChange={(e)=>{
                                               this.setState({
                                                   UserMobile:e.target.value
                                               })
                                           }}
                                       />
                                       <label className="form-control-placeholder">Mobile Number</label>
                                   </div>
                               </div>
                               <div className="mt-4">
                                   <div className="bp3-input-group">
                                       <input
                                           type="text"
                                           value={this.state.UserEmail}
                                           className="bp3-input"
                                           required
                                           onChange={(e)=>{
                                               this.setState({
                                                   UserEmail:e.target.value
                                               })
                                           }}
                                       />
                                       <label className="form-control-placeholder">Email Address</label>
                                   </div>
                               </div>
                               <div className="mt-5 d-flex flex-column">
                                   <label>When should we call you?</label>
                                   <DateInput
                                       className="w-100"
                                       leftIcon="timeline-events"
                                       closeOnSelection={true}
                                       value={this.state.date}
                                       formatDate={date => date.toLocaleDateString('en-IN')}
                                       parseDate={str => new Date(str)}
                                       onChange={this.handleDateChange}
                                       placeholder={"DD/MM/YYYY"}
                                       popoverProps={{ position: Position.BOTTOM }}
                                       rightElement={<span className="bp3-icon bp3-icon-calendar inputaddon-icon"></span>}
                                   />
                                   
                               </div>
                               <div className="mt-4">
                                   <label>Time</label>
                                   <div className="row mt-1">
                                       <div className="col-lg-3 col-md-4 col-sm-4 col-12">
                                           <CreatableSelect
                                               selected={this.state.UserHours}
                                               options={TimeHour}
                                               backspaceRemoves={true}
                                               placeholder="HH"
                                               onChange={(e)=>{
                                                   this.setState({
                                                       UserHours:e.value
                                                   })
                                               }}
                                           />
                                       </div>
                                       <div className="col-lg-3 col-md-4 col-sm-4 col-12 mt-s-4">
                                           <CreatableSelect
                                               selected={this.state.UserMinutes}
                                               options={TimeMinute}
                                               backspaceRemoves={true}
                                               placeholder="MM"
                                               onChange={(e)=>{
                                                   this.setState({
                                                       UserMinutes:e.value
                                                   })
                                               }}
                                           />
                                       </div>
                                   </div>
                               </div>

                               <div className="mt-5 mt-s-4 text-center">
                                   <Button
                                       type={"submit"}
                                       text={"Submit"}
                                       intent={Intent.PRIMARY}
                                       className="bp3-large col-md-6 col-12"
                                       onClick={()=>{this.InquirySubmit()}}
                                   />
                               </div>
                           </div>
                       </div>
                    </div>
                </Dialog>
            </React.Fragment>
        )
    }
}