import React from 'react'
import { Route } from 'react-router-dom'

const PrivateRoute = ({ component: Component, ...rest }) => (

    <Route {...rest} render={(props) => (
        <div className="hotel_booking">
            <Component {...props} />
           
        </div>
    )}/>
);

export default PrivateRoute;
