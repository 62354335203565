const APP_ENV = 'PRODUCTION'; // LOCAL,DEV,PRODUCTION
const MAINTENANCE_MODE = false;

const config = {
   ENV                 : APP_ENV,
   IS_MAINTENANCE_MODE : MAINTENANCE_MODE,

   APP_URL             : 'https://holidays.globalgarner.com',
   API_URL             : 'https://holiday-api.globalgarner.com/user/packege/v1.0.3/',
   INQUIRY_API_URL     : 'https://inquiry-api.globalgarner.com/api/'
};

if (config.ENV === 'LOCAL') {

   config.APP_URL = 'http://localhost:3000';
   config.API_URL = 'http://localhost/big_break_packege/public/user/packege/v1.0.3/';
   config.INQUIRY_API_URL = 'https://inquiry-admin.globalgarner.in/api/';
}

if (config.ENV === 'DEV') {

   config.APP_URL = 'https://holidays.globalgarner.in';
   config.API_URL = 'https://holiday-api.globalgarner.in/user/packege/v1.0.3/';
   config.INQUIRY_API_URL = 'https://inquiry-admin.globalgarner.in/api/';
}

export const packageConfigure={
   APP_URL             : config.APP_URL,
   API_URL             : config.API_URL,
   APP_ENV             : config.ENV
}

config.REACT_APP_GOOGLE_ANALYTICAL_CODE='UA-92068611-22';
config.REACT_APP_SENTRY_URL='https://041c33ad7c914137bc7676414331c9cf@sentry.io/1502307';

export default config;
