import {connect} from 'react-redux';
import MainComponent from "../components/Main";

import {getSourceSearchBox,getSourcePackage} from '../../core/actions/action';
import {sourceSearchBox,sourcePackage} from '../../core/actions';

const mapStateToProps = state => {
    return{
        sourceSearchBoxList:state.HolidayPackage.sourceSearchBoxList,
        sourcePackageList:state.HolidayPackage.sourcePackageList
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getSearchBox: (input) => {//alert("3");
            return getSourceSearchBox(input)
                .then(res => { //alert("5")
                    const sources = res.data;
                    if (sources.status === true) {
                        dispatch(sourceSearchBox(sources.data));
                    } else {
                        dispatch(sourceSearchBox([]));
                    }
                })
                .catch(error => {
                    const sources = error;
                    //console.log(error, "Main Container");
                });
        },
        getPackageList: () => {//alert("3");
            return getSourcePackage()
                .then(res => {  //console.log(res);
                    const sources = res.data;
                    if (sources.status === true) {
                        dispatch(sourcePackage(sources.data));
                    } else {
                        dispatch(sourcePackage([]));
                    }
                })
                .catch(error => {
                    const sources = error;
                    //console.log(error, "");
                });
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(MainComponent);
