import React, { Component } from 'react';
import { Card, Elevation, Button } from "@blueprintjs/core";

export default class Incusion extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            package_details:null,
        }
    }

    componentDidMount() {

        this.setState({
            package_details: this.props.package_details
        })
    }

    render(){
        return(
            <React.Fragment>
                {
                    this.state.package_details && (this.state.package_details.inclusions || this.state.package_details.inclusions) ?
                        <Card interactive={false} elevation={Elevation.TWO} className="col-12">
                            <div className="card-header">
                                <h2>Inclusions and Exclusions</h2>
                            </div>
                            <div className="card-body">
                                <h3>Inclusions</h3>
                                <React.Fragment>
                                {
                                    this.state.package_details ?
                                        <p dangerouslySetInnerHTML={{__html: this.state.package_details.inclusions}}></p>
                                    :
                                    ''
                                }
                                </React.Fragment>
                                <h3>Exclusions</h3>
                                {
                                    this.state.package_details ?
                                        <p dangerouslySetInnerHTML={{__html: this.state.package_details.exclusions}}></p>
                                    :
                                    ''
                                }
                            </div>
                        </Card>
                    :''
                }
            </React.Fragment>
        )
    }
}