import React, { Component } from 'react';
import {Link} from "react-router-dom";

export default class TopBar extends React.Component { 

    gotoData = () =>{
        

            if(this.props.history.location.state && this.props.history.location.state.query  && this.props.history.location.state.query.previous_page ) 
            { 

                let historyData = {};
                if(this.props.history.location.state.query.previous_page === "/SearchResult" )    
                {   
                    var go = '/SearchResult';
                    historyData['previous_page'] = '/';
                    historyData['current_page'] = '/SearchResult';
                    historyData['current_title'] = 'Search Result';
                    historyData['data'] = this.props.history.location.state.query.previous_page_data;
                    historyData['previous_page_data'] = this.props.history.location.state.query.data;

                    this.props.history.push(this.props.history.location.state.query.previous_page,{query:historyData})
                }else if(this.props.history.location.state.query.previous_page === "/GGHolidayPackages" ) {
                    var go = '/GGHolidayPackages';
                    historyData['previous_page'] = '/GGHolidayPackages';
                    historyData['current_page'] = '/GGHolidayPackages';
                    historyData['current_title'] = 'Search Result';
                    historyData['data'] = this.props.history.location.state.query.previous_page_data;
                    historyData['previous_page_data'] = this.props.history.location.state.query.data;

                    this.props.history.push(this.props.history.location.state.query.previous_page,{query:historyData})
                }else{
                    this.props.history.push('/',{query:[]})
                }

            }else{
            
                this.props.history.push('/',{query:[]}) 
            }
          

        


        /*if(this.props.history.location.state != 'undefined' && this.props.history.location.state.query  && this.props.history.location.state.query.previous_page ) 
        { 

            let historyData = {};
            if(this.props.history.location.state.query.previous_page === "/SearchResult" )    
            {   
                var go = '/SearchResult';
                historyData['previous_page'] = '/';
                historyData['current_page'] = '/SearchResult';
                historyData['current_title'] = 'Search Result';
                historyData['data'] = this.props.history.location.state.query.previous_page_data;
                historyData['previous_page_data'] = this.props.history.location.state.query.data;

                this.props.history.push(this.props.history.location.state.query.previous_page,{query:historyData})
            }else{
                this.props.history.push('/',{query:[]}) 
            }        

        }else{
        
            this.props.history.push('/',{query:[]}) 
        }*/
    }

    render(){ 
        return (
            <React.Fragment>
                <div className="top-bar">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 d-flex align-items-center justify-content-start">
                                <div className="row py-3">
                                    <ul className="bp3-breadcrumbs mb-breadcrumbs">
                                        <li>
                                            <div className="bp3-breadcrumbs bold pr-2" onClick={()=>this.gotoData()}>
                                                <span className="bp3-icon bp3-icon-chevron-left text-dark" > 
                                                </span>
                                            </div>
                                        </li>
                                        <li>
                                            <h1 className="m-0">
                                                {
                                                    this.props.history.location.state 
                                                        && this.props.history.location.state.query 
                                                            && this.props.history.location.state.query.current_title ? 
                                                    this.props.history.location.state.query.current_title 
                                                    : 
                                                    //this.props.history.location.pathname.search("HotelDetails") ? "Package Detail" : "SD"
                                                    this.props.history.location.pathname.search('HotelDetails') == 1 ? "Package Detail" : "Search Result"
                                                    /*this.props.history.location.pathname.search("/HotelDetails/") ?
                                                    "Hotel Details" */
                                                    //var n = str.search("jj");
                                                   //this.props.history.location.state.query.current_title
                                                }
                                            </h1>
                                        </li>
                                        
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}