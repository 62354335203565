import React from 'react';
import { Tab, Tabs } from "@blueprintjs/core";
import ToursPanel from '../container/ToursPanelContainer';
import CostomisedPanel from '../components/CostomisedPanel';
import {withRouter} from 'react-router-dom';

import Country from './Country';
import TourType from './Tour-type';
import TourDays from './TourDays';
import Destination from './Destinations';

export default class Main extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sourceSearchBoxList: null,
            sourcePackageList: null,
            activePanelOnly: false,
            animate: true,
            vertical: false,
            loadingData:true
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        this.props.getSearchBox();
        this.props.getPackageList();
    }
    
    componentWillReceiveProps(nextProps) {
        this.setState({
            sourceSearchBoxList: nextProps.sourceSearchBoxList,
            loadingData:false,
            sourcePackageList: nextProps.sourcePackageList,
        })
    }

    SearchTours = (data) =>{

        let historyData = {};
        historyData['previous_page'] = '/';
        if(data.Category == "Packages")
        {   
            var go = '/HotelDetails/'+ data.Name;
            historyData['current_page'] = '/HotelDetails/'+ data.Name;
            historyData['current_title'] = 'Package Detail';
            historyData['data'] = [];
            historyData['previous_page_data'] = [];

        }else{
            var go = '/SearchResult';
            historyData['current_page'] = '/SearchResult';
            historyData['current_title'] = 'Search Result';
            historyData['data'] = data;
            historyData['previous_page_data'] = [];
        }

        this.props.history.push(go,{query:historyData});

        /*if(data.Category == "Packages")
        {
            window.location.href = "/HotelDetails/" + data.Name;
        }else{
            this.props.history.push('/SearchResult',{query:data})
        } */
    }

    render() {
        return (
            <React.Fragment>
                <div className="hotel-strips text-center">
                        <div className="container">
                            <div className="main-heading">
                                <h1>Just think of Travel & <br/>We'll take care of the rest</h1>
                            </div>
                            <div className="stripe-wrapper">
                                <div className="retail-strip-box">
                                    <div className="strip-head">

                                    {
                                        this.state.sourceSearchBoxList ? 
                                            <Tabs
                                                id="TabsExample"
                                                animate={this.state.animate}
                                                vertical={this.state.vertical}
                                                key={this.state.vertical ? "vertical" : "horizontal"}
                                                renderActiveTabPanelOnly={this.state.activePanelOnly}
                                            >

                                                <Tab id="search_tour" title="Search Tours" 
                                                    panel={
                                                        <ToursPanel sourceSearchBoxList = {this.state.sourceSearchBoxList} 
                                                                    SearchTours={(data)=>this.SearchTours(data)}
                                                        />}                                                     
                                                />

                                                <Tab id="costomised_search" title="Customised Search" 
                                                    panel={
                                                        <CostomisedPanel 
                                                            sourceSearchBoxList = {this.state.sourceSearchBoxList} 
                                                            SearchTours={(data)=>this.SearchTours(data)}
                                                        />} 
                                                />
                                            </Tabs> 
                                        : 
                                            ''
                                    }
                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        this.state.sourcePackageList ?
                            
                            <React.Fragment> 

                                {
                                    this.state.sourcePackageList.ByCity && this.state.sourcePackageList.ByCity.length ?
                                        <React.Fragment> 
                                            <section className="country">
                                                <div className="container">
                                                    <Country 
                                                        sourcePackageList = {this.state.sourcePackageList}
                                                        SearchTours={(data)=>this.SearchTours(data)}
                                                    />
                                                </div>
                                            </section>
                                        </React.Fragment> 
                                    :
                                    ''   
                                }
                                
                                {
                                    this.state.sourcePackageList.ByCategory && this.state.sourcePackageList.ByCategory.length ?
                                        <React.Fragment> 
                                            <section className="country bg-light">
                                                <div className="container">
                                                    <TourType 
                                                        sourcePackageList = {this.state.sourcePackageList}
                                                        SearchTours={(data)=>this.SearchTours(data)}
                                                    />
                                                </div>
                                            </section>
                                        </React.Fragment> 
                                    :
                                    ''   
                                }

                                {
                                    this.state.sourcePackageList.ByCategory && this.state.sourcePackageList.ByCategory.length > 2 ?
                                        <React.Fragment> 
                                            <section className="country">
                                                <div className="container">
                                                    <TourDays 
                                                        sourcePackageList = {this.state.sourcePackageList}
                                                        SearchTours={(data)=>this.SearchTours(data)}
                                                    />
                                                </div>
                                            </section>
                                        </React.Fragment> 
                                    :
                                    ''   
                                }

                                {
                                    this.state.sourcePackageList.ByDestination && this.state.sourcePackageList.ByDestination.length ?
                                        <React.Fragment> 
                                            <section className="country bg-light">
                                                <div className="container">
                                                    <Destination 
                                                        sourcePackageList = {this.state.sourcePackageList}
                                                        SearchTours={(data)=>this.SearchTours(data)}
                                                    />
                                                </div>
                                            </section>
                                        </React.Fragment> 
                                    :
                                    ''   
                                }
                            </React.Fragment>  
                        :
                        ''    
                    }
            </React.Fragment>
        );
    }
}