import React, { Component } from 'react';
import { } from "@blueprintjs/core";
import Loading from './loading';
import defaultImage from "../../core/images/package_detail.jpg";
import {Helper} from 'gg-react-utilities';

export default class Country extends React.Component{
    constructor(){
        super();

        this.state = {
            loadingData: true,
            ByCity:null
        }
    }

    componentDidMount(){
        setTimeout(() => {
            this.setState({
                loadingData: false,
            });
        }, 1000);

        this.setState({
                ByCity:this.props.sourcePackageList.ByCity
            }
        );
    }
    
    render(){
        return(
            <React.Fragment>
                {
                    this.state.ByCity ? 
                        <React.Fragment>
                            <div className="row">
                            {
                                this.state.ByCity.map((data,i)=>{
                                    return(
                                        <React.Fragment>
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-4 mt-1">
                                                <div className="box">
                                                    <a href="" className="country-part" onClick = { () => this.props.SearchTours(data.AdvanceSearchPostData) } >
                                                        <img
                                                          src={data.ImageURL}
                                                          className="img-fluid"
                                                          onError={
                                                              (e)=>{
                                                                  e.target.onerror = null; 
                                                                  e.target.src=defaultImage 
                                                              }
                                                          } 
                                                          alt={data.Title}
                                                        />


                                                        <div className="overlay">
                                                            <h2 className="country-name">
                                                                {data.Title}
                                                            </h2>
                                                            <h4 className="pack-name">
                                                                { Helper.formatMoney(data.Price) }
                                                            </h4>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>    
                                        </React.Fragment>
                                    )
                                 })
                            }
                            </div>
                        </React.Fragment>
                    :
                    ''
                }
            </React.Fragment>
        )
    }
}
