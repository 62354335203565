import React, { Component } from 'react';
import { Card, Elevation, Button } from "@blueprintjs/core";

export default class Incusion extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            package_details:null,
        }
    }

    componentDidMount() {

        this.setState({
            package_details: this.props.package_details
        })
    }

    render(){
        return(
            <React.Fragment>
                <Card interactive={false} elevation={Elevation.TWO} className="col-12">
                    <div className="card-header">
                        <h2>Inclusions and Exclusions</h2>
                    </div>
                    <div className="card-body">
                        <h3>Inclusions</h3>
                        <React.Fragment>
                        <ol>
                        {
                            this.state.package_details ?
                                this.state.package_details.inclusion.map( (data,i)=>{
                                        return(
                                            
                                            <li>{data.Description}</li>
                                            
                                        ) 
                                    })
                            :
                            ''    
                        }
                        </ol>
                        </React.Fragment>
                        <h3>Exclusions</h3>

                        <ol>
                        {
                            this.state.package_details ?
                                this.state.package_details.exclusions.map( (data,i)=>{
                                        return(
                                            
                                            <li>{data.Description}</li>
                                            
                                        ) 
                                    })
                            :
                            ''    
                        }
                        </ol>
                    </div>
                </Card>
            </React.Fragment>
        )
    }
}