import React from 'react';
import _ from "lodash";
import {Async} from 'react-select';
import { Button,  Intent } from "@blueprintjs/core";
import debounce from 'lodash.debounce';

//import { colourOptions } from '../../data';
import {ssoInstance} from 'gg-react-utilities';
import Config from '../../Config';
export default class ToursPanel extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            sourceSearchBoxList:null,
            SearchType:2,            
            sourceCityList: [],
            sourceCity:null
        }
    }
   
    componentDidMount(){ 
        this.setState({
            sourceSearchBoxList:this.props.sourceSearchBoxList
        })
    }

    handleSearchType = (e) =>{        
        this.setState({
            SearchType:e.target.value
        })
    }

    handleAdvanceSearch = () => {   

        //this.props.nextPage('/searchResult', this.state.sourceCity);
        this.props.SearchTours(this.state.sourceCity);
    }

    async getOptions(inputValue) {
            
        if (!inputValue) {
            return [];
        }

        let ele = document.getElementsByName('search_type'); 

        for(let i = 0; i < ele.length; i++) { 
            if(ele[i].checked){
                let search_type = ele[i].value;
                
                return ssoInstance.post(Config.API_URL + 'getCity',{"searchText" : inputValue,"tripType" : search_type })
                        .then(
                            (res)=> {
                              if (res.status === 200) {
                                const response = res.data; 

                                if (response.status === true) {
                                  let holiday = [];
                                  response.data.map((resp) => {
                                      holiday.push({
                                         Category: resp.Category,
                                         ItemName: resp.ItemName,
                                         CityID: resp.CityID,
                                         StateID: resp.StateID,
                                         CountryID: resp.CountryID,
                                         Name: resp.Name,
                                         value: resp.Category,
                                         label: resp.ItemName
                                     });
                                  })
                                  return holiday;
                                }
                              }
                            } 
                        );

            } 
        } 
    }


    render(){
  
        return(

            <React.Fragment>
                
                <div>
                    <div className="row my-2 ml-0">

                        { 
                            this.state.sourceSearchBoxList && this.state.sourceSearchBoxList.tripType ? 
                                <React.Fragment>
                                    {
                                        this.state.sourceSearchBoxList.tripType.map( (data,i)=>{
                                            return(
                                                <div className="pl-2 ml-2 ml-s-0 pl-s-0 mt-s-2" Key={i}>
                                                    <label className="bp3-control bp3-radio">
                                                    <input type="radio" className="form-check-input" id="search_type" name="search_type"
                                                          value={data.type} onChange={(e) => this.handleSearchType(e)}
                                                          checked={data.type == this.state.SearchType ? true : false}
                                                    />
                                                    <span
                                                        className="bp3-control-indicator"></span>{data.text}
                                                    </label>
                                                </div>
                                            )
                                        })
                                    }
                                </React.Fragment>
                            :
                            ""
                        }
                    </div>

                    <div className="row">
                        <div className="w-100">
                            <div className="d-sm-flex justify-content-between">
                                <div className="col-xl-9 col-lg-9 col-md-8 col-sm-6 col-12 mt-s-2">
                                    
                                    <Async
                                        isClearable
                                        multi={false}
                                        value={this.state.sourceCity}
                                        isLoading={true}
                                        onChange={(value)=>
                                          {   
                                            this.setState({
                                              sourceCity:value
                                            })    
                                          }
                                        }
                                        valueKey="CityID"
                                        labelKey="ItemName"
                                        Key="CountryID"
                                        loadOptions={this.getOptions}
                                        placeholder={'Where do you want to go?'}
                                        searchPromptText={"Please enter 3 or More characters"}
                                        backspaceRemoves={true}
                                        defaultOptions
                                    />
                                </div>
                                <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 mt-s-4">
                                    <Button
                                        text="Search"
                                        intent={Intent.PRIMARY}
                                        className="search_btn"
                                        onClick={ this.state.sourceCity ? ()=>this.handleAdvanceSearch() : '' }
                                    />
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    
            </React.Fragment>
        )
    }
}