import React, {Component} from 'react';
import PrivateRoute from './PrivateRoute';
import { Switch } from 'react-router-dom';
import {Notfound, Maintance} from "gg-react-utilities";
import config from '../Config';
 
import '../../node_modules/normalize.css/normalize.css';
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";

import "gg-react-utilities/src/css/style.css";
import "gg-react-utilities/src/css/gg-styles.css";

import Home from '../home';
import SearchResult from '../SearchResult';
import HotelDetails from '../HotelDetails';
import GGHolidayPackages from '../GGHolidayPackage';
import GGHolidayPackageDetails from '../GGHolidayPackageDetails';

export default class Root extends Component{
    render(){

        if(config.IS_MAINTENANCE_MODE){
            return(
                <PrivateRoute path="*" component={Maintance} />
            );
        }

        return(
            <Switch>
                <PrivateRoute exact path="/" component={Home} />
                <PrivateRoute exact path="/SearchResult" component={SearchResult} />
                <PrivateRoute exact path="/HotelDetails/:package_name" component={HotelDetails} />
                <PrivateRoute exact path="/GGHolidayPackages" component={GGHolidayPackages} />
                <PrivateRoute exact path="/GGHolidayPackages/HotelDetails/:package_name" component={GGHolidayPackageDetails} />
                <PrivateRoute path="*" component={Notfound}/>
            </Switch>
        );
    }
}