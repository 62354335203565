import React, { Component } from 'react';
import { } from "@blueprintjs/core";
import Loading from './loading';
import defaultImage from "../../core/images/package_detail.jpg";

export default class Destination extends React.Component{
    constructor(){
        super();

        this.state = {
            LoadingData: true,
            ByDestination:null
        }
    }

    componentDidMount(){
        setTimeout(() => {
            this.setState({
                LoadingData: false
            })
        }, 1000);

        this.setState({
                ByDestination:this.props.sourcePackageList.ByDestination
            }
        );
    }

    render(){
        return(
            <React.Fragment>
                <div className="col-12">
                    <div className="row">
                        <h2 className="destination-name">DESTINATIONS</h2>
                    </div>
                </div>
                <div className="row mt-4">
                   
                    {
                        this.state.ByDestination ? 
                        <React.Fragment>
                            <div className="row mt-4">
                                {
                                    this.state.ByDestination.map((data,i)=>{

                                        return(
                                            <React.Fragment>
                                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 mb-4 mt-1">
                                                    <div className="destination">
                                                        <a href="" className="country-part" onClick = { () => this.props.SearchTours(data.AdvanceSearchPostData) }>
                                                            <img src={data.ImageURL} 
                                                            className="img-fluid" alt={data.Title} 
                                                                onError={
                                                                  (e)=>{
                                                                      e.target.onerror = null; 
                                                                      e.target.src=defaultImage 
                                                                  }
                                                              }
                                                            />
                                                            <div className="overlay">
                                                                <h2 className="tour-name mb-5">
                                                                    {data.Title}
                                                                </h2>
                                                                <div className="pt-5">
                                                                    <button className="btn-explore">Explore</button>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </div>
                        </React.Fragment>
                        :
                        ''
                    }
                </div>
                
            </React.Fragment>
        )
    }
}
