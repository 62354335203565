import {ssoInstance} from 'gg-react-utilities';
import Config from '../../Config';

export const getSourceSearchBox = () => {//alert("4")
    return ssoInstance.get(Config.API_URL + 'searchBox');
}

export const getSourceSearchData = (input) => { 
    return ssoInstance.post(Config.API_URL + 'advanceSearch',input);
}

export const getSourceHotelDetails = (input) => {
    return ssoInstance.post(Config.API_URL + 'detailsByName',{PackageName: input} );
}

export const setInquiryDetails = (input) => {
    return ssoInstance.post(Config.API_URL + 'addEnquiry',input);
}

export const getSourceCitys = (input,tripType) => {//alert("4")
    return ssoInstance.post(Config.API_URL + 'getCity',
           {	
               "searchText" : input,
               "tripType" : tripType,
           });
}
export const getSourcePackage = () => {//alert("4")
    return ssoInstance.get(Config.API_URL + 'packageList');
}
export const getSourceGGHolidayPackages = () => {
    return ssoInstance.get(Config.INQUIRY_API_URL + 'gg-holiday-packages');
}

export const getSourceGGHolidayPackagesDetails = (id) => {
    return ssoInstance.get(Config.INQUIRY_API_URL + 'gg-holiday-package-details/'+id);
}

export const setGGHolidayInquiryDetails = (input) => {
    return ssoInstance.post(Config.INQUIRY_API_URL + 'gg-holiday-package-inquiry',input);
}