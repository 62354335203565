import React from 'react';
import { Button,  Intent } from "@blueprintjs/core";
import CreatableSelect from 'react-select/lib/Creatable';
import Select from 'react-select';

import {ssoInstance} from 'gg-react-utilities';
import Config from '../../Config';


export default class CostomisedPanel extends React.Component 
{
    constructor(props){
        super(props);

        this.state = {
            sourceSearchBoxList:null,
            SearchType:2,
            region:null,
            region_id:null,
            country:null,
            country_id:null,
            city:null,
            city_id:null,
            filterData:null,
            duration_id:null,
            budget_id:null,
            inclusion_id:null,
            category_id:null,
            month_id:null
        }
    }

    componentDidMount(){ 
        this.setState({
            sourceSearchBoxList:this.props.sourceSearchBoxList
        })
    }

   
    handleSearchRegion = (e) =>{        
        
        ssoInstance.post(Config.API_URL + 'getCountry',{region_id: e.value} ).then((res) =>{
            if (res.status === 200) {
                //console.log(res.data)
                if (res.data.status === true) {
                    this.setState({
                        region_id:e.value,
                        country:res.data.data,
                        city:null
                    })
                }
            }   
        }) 
    }

    handleSearchCountry = (e) =>{        
        
        ssoInstance.post(Config.API_URL + 'getCityByCountry',{country_id: e.value} ).then((res) =>{
            if (res.status === 200) {
                
                if (res.data.status === true) {
                    this.setState({
                        country_id:e.value,
                        city:res.data.data,
                    })
                }
            }   
        }) 
    }

    handleAdvanceSearch = () => {   

        this.state.filterData = {};

        if(this.state.duration_id){ this.state.filterData['Duration']=this.state.duration_id;  }
        if(this.state.budget_id){ this.state.filterData['Budget']=this.state.budget_id;  }
        if(this.state.inclusion_id){ this.state.filterData['Inclusion']=this.state.inclusion_id;  }
        if(this.state.category_id){ this.state.filterData['PackageCategory']=this.state.category_id;  }
        if(this.state.month_id){ this.state.filterData['Month']=this.state.month_id;  }

        if(this.state.region_id){ this.state.filterData['RegionId']=this.state.region_id;  }
        if(this.state.country_id){ this.state.filterData['CountryId']=this.state.country_id;  }
        if(this.state.city_id){ this.state.filterData['CityId']=this.state.city_id;  }
        
        this.props.SearchTours(this.state.filterData);
    }

    render(){
    
        return(

            <React.Fragment>
                <div className="row">
                    <div className="col-xl-9 col-lg-9 col-md-9 col-sm-12 col-12">
                        <div className="row">

                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                                { 
                                    this.state.sourceSearchBoxList && this.state.sourceSearchBoxList.region ? 
                                        <React.Fragment>
                                            {
                                                <CreatableSelect
                                                   // isClearable
                                                    options={
                                                         this.state.sourceSearchBoxList.region.map((data,i)=>{
                                                            return({value:data.region_id,label:data.region_name})
                                                         })
                                                     }
                                                    onChange={(e) => this.handleSearchRegion(e)} 
                                                    placeholder="Search By Area"
                                                    clssName="text-left" 
                                                />
                                            }
                                        </React.Fragment>
                                    :
                                    '' 
                                }
                            </div>    
                            
                            {
                                this.state.country ? 

                                    <React.Fragment>
                                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                                            {
                                                <CreatableSelect
                                                   // isClearable
                                                    options={
                                                         this.state.country.map((data,i)=>{
                                                            return({value:data.country_id,label:data.country_name})
                                                         })
                                                     }
                                                    onChange={(e) => this.handleSearchCountry(e)} 
                                                    placeholder="Search By Country"
                                                    clssName="text-left" 
                                                />
                                            }
                                        </div>    
                                    </React.Fragment>
                                :
                                ''    
                            }

                            {
                                this.state.city ? 

                                    <React.Fragment>
                                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                                            {
                                                <CreatableSelect
                                                    //isClearable
                                                    options={
                                                         this.state.city.map((data,i)=>{
                                                            return({value:data.city_id,label:data.city_name})
                                                         })
                                                     }
                                                    onChange={
                                                        (e) => {
                                                            this.setState({
                                                                city_id:e.value,
                                                            })
                                                        }
                                                    }
                                                    placeholder="Search By City"
                                                    clssName="text-left" 
                                                />
                                            }
                                        </div>    
                                    </React.Fragment>
                                :
                                ''    
                            }

                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                                { 
                                    this.state.sourceSearchBoxList && this.state.sourceSearchBoxList.duration ? 
                                        <React.Fragment>
                                            {
                                                <CreatableSelect
                                                    //isClearable
                                                    options={
                                                         this.state.sourceSearchBoxList.duration.map((data,i)=>{
                                                            return({value:data.value,label:data.text})
                                                         })
                                                     }
                                                    onChange={
                                                        (e) => {
                                                            this.setState({
                                                                duration_id:e.value,
                                                            })
                                                        }
                                                    } 
                                                    placeholder="Search By Duration"
                                                    clssName="text-left" 
                                                />
                                            }
                                        </React.Fragment>
                                    :
                                    <span></span> 
                                }
                            </div>

                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                                { 
                                    this.state.sourceSearchBoxList && this.state.sourceSearchBoxList.budget ? 
                                        <React.Fragment>
                                            {
                                                <CreatableSelect
                                                   //isClearable
                                                    options={
                                                         this.state.sourceSearchBoxList.budget.map((data,i)=>{
                                                            return({value:data.value,label:data.text})
                                                         })
                                                     }
                                                    onChange={
                                                        (e) => {
                                                            this.setState({
                                                                budget_id:e.value,
                                                            })
                                                        }
                                                    } 
                                                    placeholder="Search By Budget"
                                                    clssName="text-left" 
                                                />
                                            }
                                        </React.Fragment>
                                    :
                                    <span></span> 
                                }
                            </div>
                            
                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                                { 
                                    this.state.sourceSearchBoxList && this.state.sourceSearchBoxList.inclusion ? 
                                        <React.Fragment>
                                            {
                                                <CreatableSelect
                                                    //isClearable
                                                    options={
                                                         this.state.sourceSearchBoxList.inclusion.map((data,i)=>{
                                                            return({value:data.value,label:data.text})
                                                         })
                                                     }
                                                    onChange={
                                                        (e) => {
                                                            this.setState({
                                                                inclusion_id:e.value,
                                                            })
                                                        }
                                                    } 
                                                    placeholder="Search By Inclusions"
                                                    clssName="text-left" 
                                                />
                                            }
                                        </React.Fragment>
                                    :
                                    <span></span> 
                                }
                            </div>
                            
                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                                { 
                                    this.state.sourceSearchBoxList && this.state.sourceSearchBoxList.category ? 
                                        <React.Fragment>
                                            {
                                                <CreatableSelect
                                                    //isClearable
                                                    options={
                                                         this.state.sourceSearchBoxList.category.map((data,i)=>{
                                                            return({value:data.value,label:data.text})
                                                         })
                                                    }
                                                    onChange={
                                                        (e) => {
                                                            this.setState({
                                                                category_id:e.value,
                                                            })
                                                        }
                                                    }
                                                    placeholder="Search By Interest"
                                                    clssName="text-left" 
                                                />
                                            }
                                        </React.Fragment>
                                    :
                                    <span></span> 
                                }
                            </div>

                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                                { 
                                    this.state.sourceSearchBoxList && this.state.sourceSearchBoxList.month ? 
                                        <React.Fragment>
                                            {
                                                <CreatableSelect
                                                    //isClearable
                                                    options={
                                                         this.state.sourceSearchBoxList.month.map((data,i)=>{
                                                            return({value:data.value,label:data.text})
                                                         })
                                                    }
                                                    onChange={
                                                        (e) => {
                                                            this.setState({
                                                                month_id:e.value,
                                                            })
                                                        }
                                                    }
                                                    placeholder="Search By Month"
                                                    clssName="text-left" 
                                                />
                                            }
                                        </React.Fragment>
                                    :
                                    <span></span> 
                                }
                            </div>    
                            
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 d-flex justify-content-end align-item-end mt-s-4 mt-sm-4 mt-0">
                        <div className="d-flex align-items-end">
                            <Button
                                text="Search"
                                intent={Intent.PRIMARY}
                                className="search_btn"
                                onClick={ () => this.handleAdvanceSearch() }
                            />
                        </div>
                    </div>
                </div>            
            </React.Fragment>
        )
    }

}










