import React, { Component } from 'react';
import { Card, Elevation, Button } from "@blueprintjs/core";

export default class Cancellation extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            package_details:null
        }
    }

    componentDidMount() {

        this.setState({
            package_details: this.props.package_details
        })
    }

    render(){
        return(
            <React.Fragment>
                <Card interactive={false} elevation={Elevation.TWO} className="col-12">
                    <div className="card-header">
                        <h2>Cancellation</h2>
                    </div>
                    <div className="card-body">
                        <h3>Terms & Conditions</h3>
                        <ol>
                        {
                            this.state.package_details && this.state.package_details.term_conditions?
                                this.state.package_details.term_conditions.map( (data,i)=>{
                                    return(<li>{data.Description}</li>) 
                                })
                            :
                            ''    
                        }
                        </ol>

                        <h3>Cancellation Charges</h3>
                        <table className="bp3-html-table bp3-html-table-bordered">
                            <thead>
                                <tr>
                                    <th>No of days prior to departure</th>
                                    <th>Charges</th>
                                    <th>Applied On</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.package_details && this.state.package_details.cancellation_policy ?
                                        this.state.package_details.cancellation_policy.map( (data,i)=>{
                                            return(
                                                <tr>
                                                    <td>{data.no_of_days_prior_to_departure}</td>
                                                    <td>{data.charge}</td>
                                                    <td>{data.applied_on}</td>
                                                </tr>
                                            ) 
                                        })
                                    :
                                    ''    
                                }
                                
                            </tbody>
                        </table>
                    </div>
                </Card>
            </React.Fragment>
        )
    }
}

