import React, { Component } from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/lib/Creatable';
import { Card, Elevation, Button, Checkbox } from "@blueprintjs/core";
import { Month } from '../../core/components/data';

export default class Cancellation extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            package_details:null,
            calender:null,
            TableData: []
        }
    }

    componentDidMount() {

        this.setState({

            package_details: this.props.package_details,

            calender:this.props.package_details.price_details_calendar && this.props.package_details.price_details_calendar.month_data ? 
                        this.props.package_details.price_details_calendar.month_data[0].detail : null

        })
    }


    setHolidayPackagePrice = (price) =>{

       this.props.HolidayPackagePrice(price);
       window.scrollTo({
           top: 0,
           behavior: 'smooth',
       })
   }

    onSelectMonth = (e) => {

        this.setState({

            calender:this.props.package_details.price_details_calendar && 
                        this.props.package_details.price_details_calendar.month_data ? 
                            this.props.package_details.price_details_calendar.month_data[e.target.value].detail : null

        })
    }


    PriceTableCreate = (data) =>{
        return data.map((list, i) => {
            if (list.type == 0) {
                return (
                    <td className="date-passed" key={i}></td>
                )
            }
            if (list.type == 1) {
                return (
                    <td className="date-passed" key={i}><span>{list.date}</span></td>
                )
            }
            if (list.type == 2) {
                return (
                    <td className="available" key={i}><a href='javascript:void(0)' 
                    onClick={(price)=>{this.setHolidayPackagePrice(list.price)}}
                    >{list.date}<span
                        className='price-text'>&#8377; {list.price}</span></a></td>
                )
            }
        })
    }

    render(){

        let TableData = [];
        
        return(
            <React.Fragment>
                <Card interactive={false} elevation={Elevation.TWO} className="col-12">
                    <div className="card-header">
                        <h2>Date & Price</h2>
                    </div>
                    <div className="card-body">
                    {
                        this.state.package_details && this.state.package_details.price_details_calendar && this.state.package_details.price_details_calendar.hotel_category?
                            
                            <React.Fragment>
                                <h3>Hotel Category</h3>
                                <div className="col-12">
                                    <div className="row d-flex flex-column">
                                        <Checkbox
                                            label={this.state.package_details.price_details_calendar.hotel_category.text}
                                            checked={true}
                                            //onChange={this.handleEnabledChange}
                                        />
                                    </div>
                                </div>
                            </React.Fragment>
                        :
                        ''
                    }

                    {
                        this.state.package_details && this.state.package_details.price_details_calendar && this.state.package_details.price_details_calendar.month_data?
                            
                            <React.Fragment>
                                <div className="col-12">
                                    <div className="row d-flex flex-column">
                                        <h3>Check Price and Dates:</h3>
                                        <div className="col-lg-4 col-md-5 col-sm-6 col-12 px-0">
                                            
                                            <div className="form-group">
                                                <select className="form-control w-100" onChange={(e)=>{this.onSelectMonth(e)}}>
                                                    {
                                                        this.state.package_details.price_details_calendar.month_data.map((data,i)=> {
                                                            return(
                                                                <option value={i} key={i}>{data.month}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>



                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        :
                        ''
                    }

                    {
                        //this.props.package_details.price_details_calendar && this.props.package_details.price_details_calendar.month_data[0].detail ? 
                        this.state.calender ?
                            <div className="col-12 my-4">
                                    <div className="row d-flex flex-column">
                                        <div id="calendar-tab" className="p-4 calendar-legend table-responsive">
                                            <div className="row">
                                                <div className="col-sm-12 px-s-0">
                                                    <div className="calendar">
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <td>sunday</td>
                                                                    <td>monday</td>
                                                                    <td>tuesday</td>
                                                                    <td>wednesday</td>
                                                                    <td>thursday</td>
                                                                    <td>friday</td>
                                                                    <td>saturday</td>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                            {
                                                                this.state.calender.map((data, i) => {
                                                                    if(i !=0 && data.day =="Sun"){
                                                                        this.state.TableData = [];
                                                                    }

                                                                    if(i == 0){

                                                                        this.state.TableData = [];
                                                                        let date = new Date(data.date);
                                                                        let WeekDay=date.getDay();

                                                                        for (let j = 0; j < WeekDay; j++) {
                                                                            this.state.TableData.push({type:0,className:'prev-month'});
                                                                        }

                                                                        if(data.having_data) {
                                                                            this.state.TableData.push({type:2,className:'available',date:(i+1),price:data.price});
                                                                        }else {
                                                                            this.state.TableData.push({type:1,className:'date-passed',date:(i+1)});
                                                                        }

                                                                    }else{
                                                                        if(data.having_data) {
                                                                            this.state.TableData.push({type:2,className:'available',date:(i+1),price:data.price});
                                                                        }else {
                                                                            this.state.TableData.push({type:1,className:'date-passed',date:(i+1)});
                                                                        }
                                                                    }

                                                                    if(data.day == "Sat" || this.state.calender.length == (i+1)){

                                                                        return (
                                                                            <tr key={i}>
                                                                                {this.PriceTableCreate(this.state.TableData)}    
                                                                            </tr>
                                                                        )
                                                                    }

                                                                })
                                                            }
                                                                
                                                            
                                                            
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    
                                                </div>
                                                <div className="calendar-legend">
                                                    <label className="available">available</label>
                                                    <label className="unavailable">unavailable</label>
                                                    <label className="past">past</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>  
                        :
                        <div></div>
                    }

                    {
                        this.state.package_details && this.state.package_details.package_room_costings ?
                            
                            <React.Fragment>
                                <div className="col-12">
                                    <div className="row d-flex flex-column">
                                        <h3>Holiday Price</h3>

                                        <div className="col-12 px-0">
                                            <table className="bp3-html-table bp3-html-table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>Room Type</th>
                                                        <th>Total Price (INR)</th>
                                                        <th>Validity</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.package_details.package_room_costings.map((data,i)=>{
                                                            return(
                                                                <React.Fragment>
                                                                    <tr>
                                                                        <td>{data.RoomType}</td>
                                                                        <td>{data.TotalPrice}</td>
                                                                        <td>{data.ValidFrom} - {data.ValidTo}</td>
                                                                    </tr>  
                                                                </React.Fragment>
                                                            ) 
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        :
                        ''
                    }    

                    {
                        this.state.package_details && this.state.package_details.accommodation ?
                            
                            <React.Fragment>
                                <div className="col-12">
                                    <div className="row d-flex flex-column">
                                        <h3>Accommodation</h3>

                                        <div className="col-12 px-0">
                                            <table className="bp3-html-table bp3-html-table-bordered">
                                                <thead>
                                                <tr>
                                                    <th>City Name</th>
                                                    <th>Hotel Name</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.package_details.accommodation.map((data,i)=>{
                                                            return(
                                                                <React.Fragment>
                                                                    <tr>
                                                                        <td>{data.CityName}</td>
                                                                        <td>{data.HotelName}</td>
                                                                    </tr>  
                                                                </React.Fragment>
                                                            ) 
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        :
                        ''
                    }

                    <div className="col-12 mt-4">
                            <div className="row d-flex flex-column">
                                <div className="gg-alert gg-alert-warning" role="alert">
                                    <b>Note :</b> Hotels are subject to change due to availability reasons, and may be substituted with others of similar category and star rating.
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
            </React.Fragment>
        )
    }
}