import React, { Component } from 'react';
import { Card, Elevation, Button, Icon } from "@blueprintjs/core";

export default class Overview extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            package_details:null,
        }
    }

    componentDidMount() {

        this.setState({
            package_details: this.props.package_details
        })
    }


    render(){
        return(
            <React.Fragment>
                <Card interactive={false} elevation={Elevation.TWO} className="col-12">
                    <div className="card-header">
                        <h2>Overview</h2>
                    </div>

                    <div className="card-body">
                        <br/>

                        { 
                            this.state.package_details ? 
                            <p dangerouslySetInnerHTML={{__html: this.state.package_details.description}}></p>
                            :'' 
                        }
                        

                        {/*<div className="col-12 my-4">
                            <div className="row">
                                { 
                                   this.state.package_details ? 
                                    this.state.package_details.package_component.map( (data,i)=>{
                                        return(<img title={data.include_text} className="text-light mr-3" iconSize={25} src={data.include_logo}/>)                                   
                                    } ) 
                                    : 
                                    '' 
                                }
                            </div>
                        </div>


                        <div className="col-12 mt-5 mb-4">
                            
                            { 
                               this.state.package_details ? 
                                this.state.package_details.itinerary.map( (data,i)=>{
                                    return(<React.Fragment>
                                            <div className="row day-panel mt-3">
                                                <div className="panel-heading">
                                                    <h5>Day {data.Days}</h5>
                                                </div>
                                                <div className="panel-body">
                                                    <h5>{data.BriefDesc}</h5>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <p>{data.Description}</p>
                                            </div>
                                            </React.Fragment>
                                        )
                                } ) 
                                : 
                                '' 
                            }
                        </div>*/}
                    </div>
                </Card>
            </React.Fragment>
        )
    }
}