import React from 'react';
import MainContainer from './container/MainContainer';



export default class Home extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            activePanelOnly: false,
            animate: true,
            vertical: false
        };
    }

    render(){
        return(
            <React.Fragment>
                <main>
                    
                    <MainContainer history={this.props.history} />

                </main>
            </React.Fragment>
        )
    }
}
