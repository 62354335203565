import React, { Component } from 'react';
import { } from "@blueprintjs/core";
import Loading from './loading';
import defaultImage from "../../core/images/package_detail.jpg";

export default class TourType extends React.Component{
    constructor(){
        super();

        this.state = {
            loadingData: true,
            ByCategory:null
        }
    }

    componentDidMount(){
        setTimeout(() => {
            this.setState({
                loadingData: false
            })
        }, 1000);

        this.setState({
                ByCategory:this.props.sourcePackageList.ByCategory
            }
        );
    }

    render(){
        return(
            <React.Fragment>
                {
                    this.state.ByCategory ?
                    <React.Fragment>
                        {
                            <div className="row">
                            {
                                this.state.ByCategory.map((data,i)=>{

                                    if(i < 2){
                                        return(
                                        <React.Fragment>
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                                <a href="" className="country-part" onClick = { () => this.props.SearchTours(data.AdvanceSearchPostData) } >
                                                    <img src={data.ImageURL} className="img-fluid"
                                                        onError={
                                                              (e)=>{
                                                                  e.target.onerror = null; 
                                                                  e.target.src=defaultImage 
                                                              }
                                                          }
                                                        alt={data.Title}/>
                                                    <div className="overlay">
                                                        <h2 className="country-name">
                                                            {data.Title}
                                                        </h2>
                                                        <h4 className="pack-name">
                                                            ( {data.Count} Tours ) 
                                                        </h4>
                                                    </div>
                                                </a>
                                            </div>    
                                        </React.Fragment>
                                    )
                                    }
                                    
                                 })
                            }
                            </div>
                        }
                    </React.Fragment>
                    :
                    ''
                }
            </React.Fragment>
        )
    }
}
