import React, {Component} from 'react';
import { Card } from "@blueprintjs/core";

export default class Loading extends React.Component{
    render(){
        return(
            <div className="col-12 mt-2">
                <div className="row mt-4">
                    <div className="col-md-6 col-xl-6 col-lg-6 col-sm-6 col-12 mb-4">
                        <Card className='p-1 border-0 shadow-none' style={{boxShadow: "none"}}>
                            <center>
                                <div className="bp3-skeleton" style={{height: "350px"}} />
                                <br/>
                            </center>
                        </Card>
                    </div>
                    <div className="col-md-6 col-xl-6 col-lg-6 col-sm-6 col-12 mb-4">
                        <Card className='p-1 border-0 shadow-none' style={{boxShadow: "none"}}>
                            <center>
                                <div className="bp3-skeleton" style={{height: "350px"}} />
                                <br/>
                            </center>
                        </Card>
                    </div>
                    <div className="col-md-6 col-xl-6 col-lg-6 col-sm-6 col-12 mb-4">
                        <Card className='p-1 border-0 shadow-none' style={{boxShadow: "none"}}>
                            <center>
                                <div className="bp3-skeleton" style={{height: "350px"}} />
                                <br/>
                            </center>
                        </Card>
                    </div>
                    <div className="col-md-6 col-xl-6 col-lg-6 col-sm-6 col-12 mb-4">
                        <Card className='p-1 border-0 shadow-none' style={{boxShadow: "none"}}>
                            <center>
                                <div className="bp3-skeleton" style={{height: "350px"}} />
                                <br/>
                            </center>
                        </Card>
                    </div>
                </div>
            </div>
        )
    }
}
