import React, { Component } from 'react';
import { Checkbox, Alignment, label, Button, Intent, Collapse } from "@blueprintjs/core";
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import Drawer from '@material-ui/core/Drawer';

export default class Filter extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            isPriceOpen: true,
            isThemeOpen: true,
            isPackageOpen: false,
            isFilterDrawer: false,
        
            price : {min:'0',maxValue:'0'},
            price_min_value:0,
            price_max_value:0,


            filter_min_price:null,
            filter_max_price:null,

            searchData:this.props.searchData

        }
    }

    componentDidMount(props){
        
        if(this.props.sourceSearchDataList.result && this.props.sourceSearchDataList.result.length > 0){
            this.setState({
                sourceSearchDataList: this.props.sourceSearchDataList,
                price_min_value: parseInt(this.props.sourceSearchDataList.filterData.price.min),
                price_max_value:parseInt(this.props.sourceSearchDataList.filterData.price.max),
                price:{ 
                    min: parseInt(this.props.sourceSearchDataList.filterData.price.min), 
                    max: parseInt(this.props.sourceSearchDataList.filterData.price.max) 
                }
            })
        }
        if(this.props.searchData && this.props.searchData.filter_priceFrom)
        {
            this.setState({
                price:{ 
                    min: parseInt(this.props.searchData.filter_priceFrom), 
                    max: parseInt(this.props.searchData.filter_priceTo) 
                }
            })
        }    
        //console.log(this.props.searchData)
    }

    PriceClick = () => {
        this.setState({
            isPriceOpen: !this.state.isPriceOpen,
        });
    }
    ThemeClick = () => {
        this.setState({
            isThemeOpen: !this.state.isThemeOpen,
        });
    }
    PackageClick = () => {
        this.setState({
            isPackageOpen: !this.state.isPackageOpen,
        });
    }
    toggleDrawer = () => {
        this.setState({
            isFilterDrawer: true,
        })
    }
    
    ChangeSlider = (e) => {
        //console.log(e)
        this.setState({
            price: { min:e.min, max: e.max }
        })        
    }
    filterReset = () => {
        
        delete this.state.searchData['filter_theme'];
        delete this.state.searchData['filter_include'];
        delete this.state.searchData['filter_priceFrom'];
        delete this.state.searchData['filter_priceTo'];
        this.props.SearchTours(this.state.searchData);
    }
    selectPrice = (e) => {
        
        this.setState({
            filter_min_price:e.min,
            filter_max_price:e.max,
        })

        if(this.state.searchData && !this.state.searchData.filter_priceFrom){
            this.state.searchData.filter_priceFrom=this.props.sourceSearchDataList.filterData.price.min;
        }

        if(this.state.searchData && !this.state.searchData.filter_priceTo){
            this.state.searchData.filter_priceTo=this.props.sourceSearchDataList.filterData.price.max;
        }

        this.state.searchData.filter_priceFrom=e.min;    
        this.state.searchData.filter_priceTo=e.max;    

        this.props.SearchTours(this.state.searchData);
    }
    selectTheme = (e) => {

        if(this.state.searchData && !this.state.searchData.filter_theme){
            this.state.searchData.filter_theme=[];
        }    

        if(e.target.checked){

            this.state.searchData.filter_theme.push(e.target.value)
            
        }else{
            var index = this.state.searchData.filter_theme.indexOf(e.target.value)
            if (index !== -1) {
                this.state.searchData.filter_theme.splice(index, 1);
            }
        }

        this.props.SearchTours(this.state.searchData);
    }

    selectInclude = (e) => {

        if(this.state.searchData && !this.state.searchData.filter_include){
            this.state.searchData.filter_include=[];
        }    

        if(e.target.checked){

            this.state.searchData.filter_include.push(e.target.value)
            
        }else{
            var index = this.state.searchData.filter_include.indexOf(e.target.value)
            if (index !== -1) {
                this.state.searchData.filter_include.splice(index, 1);
            }
        }

        this.props.SearchTours(this.state.searchData);
    }
    
    render(){
        return(
            <React.Fragment>
                {
                <React.Fragment>



                    <Drawer className="" open={this.state.isFilterDrawer} onClose={() => {
                    this.setState({
                        isFilterDrawer: false
                    })
                }} style={{width: '500px'}}>
                    <div className="w-100 visible-xs visible-sm hidden-md hidden-lg hidden-xl px-s-3 mt-s-3 px-sm-3 mt-sm-3">
                        <div className="border-theme col-12">
                            <h5>Showing 20 of 20 Packages.</h5>
                        </div>

                        <div className="border-theme col-12 mt-3 py-3">
                            <div className="d-flex justify-content-between align-items-center">
                                <Button className="btn-reset text-dark">Filter</Button>
                                <Button className="btn-reset" intent={Intent.PRIMARY}>Reset all</Button>
                            </div>
                        </div>

                        <div className="border-theme col-12 mt-3 py-3 font-20">
                            <a
                                onClick={this.PriceClick}
                                className="text-left d-flex justify-content-between text-dark pr-2">
                                <h3>Price</h3>
                                {this.state.isPriceOpen ? "-" : "+"}
                            </a>
                            <Collapse isOpen={this.state.isPriceOpen} keepChildrenMounted={this.state.keepChildrenMounted}>
                                <div className="my-4 px-2">
                                    <InputRange
                                        className="my-4"
                                        maxValue={this.state.price_max_value}
                                        minValue={this.state.price_min_value}
                                        value={this.state.price}
                                        onChangeComplete={(e) => this.selectPrice(e)}
                                    />
                                </div>
                            </Collapse>
                        </div>

                        
                        <div className="border-theme col-12 mt-3 py-3 font-20">
                            <a
                                onClick={this.ThemeClick}
                                className="d-flex justify-content-between text-dark pr-2">
                                <h3>Themes</h3>
                                {this.state.isThemeOpen ? "-" : "+"}
                                
                                
                            </a>
                            <Collapse isOpen={this.state.isThemeOpen} keepChildrenMounted={this.state.keepChildrenMounted}>
                                <div className="mt-3">
                                    {
                                        (this.state.sourceSearchDataList && this.state.sourceSearchDataList.filterData
                                        && this.state.sourceSearchDataList.filterData.theme) ?
                                        
                                           this.state.sourceSearchDataList.filterData.theme.map((data,i)=>{
                                                
                                                return(
                                                  <li>
                                                    <div className="d-flex align-items-center">
                                                        <div className="col-md-2 col-sm-3 col-3 pl-2 d-flex">
                                                            <span className="bp3-icon bp3-icon-lock">
                                                                <img className="bp3-icon " src={data.logo}/>
                                                            </span>
                                                        </div>
                                                        <div className="col-md-10 col-sm-9 col-9 pl-1 pr-0">

                                                            <Checkbox
                                                                Alignment="Alignment.RIGHT"
                                                                checked={   
                                                                    this.state.searchData && this.state.searchData.filter_theme ?
                                                                        this.state.searchData.filter_theme.includes(""+data.value+"") ?
                                                                            true :false     
                                                                    :
                                                                    false
                                                                }

                                                                label={data.text}
                                                                value={data.value}
                                                                onClick={ (e) => this.selectTheme(e) } 
                                                            />

                                                        </div>
                                                    </div>
                                                  </li>
                                                )
                                            }) :''
                                    }
                                </div>
                            </Collapse>
                        </div>

                        




                        <div className="border-theme col-12 mt-3 py-3 font-20">
                                    <a
                                        onClick={this.PackageClick}
                                        className="d-flex justify-content-between text-dark pr-2">
                                        <h3>Package Includes</h3>
                                        {this.state.isPackageOpen ? "-" : "+"}
                                    </a>
                                    <Collapse isOpen={this.state.isPackageOpen} keepChildrenMounted={this.state.keepChildrenMounted}>
                                        <div className="mt-3">
                                            {
                                                (this.state.sourceSearchDataList &&
                                                    this.state.sourceSearchDataList.filterData &&
                                                        this.state.sourceSearchDataList.filterData.package_include) ?

                                                this.state.sourceSearchDataList.filterData.package_include.map((data,i)=>{
                                                    return(
                                                        <li>
                                                            <div className="d-flex align-items-center">
                                                                <div className="col-md-2 col-sm-3 col-3 pl-2 d-flex">
                                                                    <span className="bp3-icon bp3-icon-lock">
                                                                        <img className="bp3-icon " src={data.logo}/>
                                                                    </span>
                                                                </div>
                                                                <div className="col-md-10 col-sm-9 col-9 pl-1 pr-0">
                                                                    <Checkbox
                                                                        Alignment="Alignment.RIGHT"
                                                                        label={data.text}
                                                                        value={data.value}
                                                                        onClick={ (e) => this.selectInclude(e) }

                                                                        checked={   
                                                                            this.state.searchData && this.state.searchData.filter_include ?
                                                                                this.state.searchData.filter_include.includes(""+data.value+"") ?
                                                                                    true :false     
                                                                            :
                                                                            false
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </li>
                                                    )
                                                })
                                                :
                                                ''
                                            }
                                        </div>
                                    </Collapse>
                                </div>
                    </div>
                </Drawer>








                    <div className="w-100 hidden-xs hidden-sm visible-md">
                        <div className="border-theme col-12">
                            <h5>Showing
                                { 
                                    this.state.sourceSearchDataList && 
                                    this.state.sourceSearchDataList.result ?
                                    " "+this.state.sourceSearchDataList.result.length+" "
                                    :' 0 ' 
                                }
                                of 
                                { 
                                    this.state.sourceSearchDataList && 
                                    this.state.sourceSearchDataList.result ?
                                    " "+this.state.sourceSearchDataList.result.length+" "
                                    :' 0 ' 
                                } 
                                Packages.
                            </h5>
                        </div>

                        <div className="border-theme col-12 mt-3 py-3">
                            <div className="d-flex justify-content-between align-items-center">
                                <Button className="btn-reset text-dark">Filter</Button>
                                <Button 
                                className="btn-reset" 
                                intent={Intent.PRIMARY}
                                onClick={ () => this.filterReset() } 
                                >Reset all</Button>
                            </div>
                        </div>

                        <div className="border-theme col-12 mt-3 py-3 font-20">
                            <a
                                onClick={this.PriceClick}
                                className="text-left d-flex justify-content-between text-dark pr-2">
                                <h3>Price</h3>
                                {this.state.isPriceOpen ? "-" : "+"}
                            </a>
                            <Collapse isOpen={this.state.isPriceOpen} keepChildrenMounted={this.state.keepChildrenMounted}>
                                <div className="my-4 px-2">
                                    <InputRange
                                        minValue={this.state.price_min_value}
                                        maxValue={this.state.price_max_value}
                                        value={this.state.price}
                                        onChange={(e) => this.ChangeSlider(e)} 
                                        onChangeComplete={(e) => this.selectPrice(e)} 
                                    />
                                </div>
                            </Collapse>    
                        </div>

                        <div className="border-theme col-12 mt-3 py-3 font-20">
                            <a
                                onClick={this.ThemeClick}
                                className="d-flex justify-content-between text-dark pr-2">
                                <h3>Themes</h3>
                                {this.state.isThemeOpen ? "-" : "+"}
                                
                                
                            </a>
                            <Collapse isOpen={this.state.isThemeOpen} keepChildrenMounted={this.state.keepChildrenMounted}>
                                <div className="mt-3">
                                    {
                                        (this.state.sourceSearchDataList && this.state.sourceSearchDataList.filterData
                                        && this.state.sourceSearchDataList.filterData.theme) ?
                                        
                                           this.state.sourceSearchDataList.filterData.theme.map((data,i)=>{
                                                
                                                return(
                                                  <li>
                                                    <div className="d-flex align-items-center">
                                                        <div className="col-md-2 col-sm-3 col-3 pl-2 d-flex">
                                                            <span className="bp3-icon bp3-icon-lock">
                                                                <img className="bp3-icon " src={data.logo}/>
                                                            </span>
                                                        </div>
                                                        <div className="col-md-10 col-sm-9 col-9 pl-1 pr-0">

                                                            <Checkbox
                                                                Alignment="Alignment.RIGHT"
                                                                checked={   
                                                                    this.state.searchData && this.state.searchData.filter_theme ?
                                                                        this.state.searchData.filter_theme.includes(""+data.value+"") ?
                                                                            true :false     
                                                                    :
                                                                    false
                                                                }

                                                                label={data.text}
                                                                value={data.value}
                                                                onClick={ (e) => this.selectTheme(e) } 
                                                            />

                                                        </div>
                                                    </div>
                                                  </li>
                                                )
                                            }) :''
                                    }
                                </div>
                            </Collapse>
                        </div>

                        <div className="border-theme col-12 mt-3 py-3 font-20">
                                    <a
                                        onClick={this.PackageClick}
                                        className="d-flex justify-content-between text-dark pr-2">
                                        <h3>Package Includes</h3>
                                        {this.state.isPackageOpen ? "-" : "+"}
                                    </a>
                                    <Collapse isOpen={this.state.isPackageOpen} keepChildrenMounted={this.state.keepChildrenMounted}>
                                        <div className="mt-3">
                                            {
                                                (this.state.sourceSearchDataList &&
                                                    this.state.sourceSearchDataList.filterData &&
                                                        this.state.sourceSearchDataList.filterData.package_include) ?

                                                this.state.sourceSearchDataList.filterData.package_include.map((data,i)=>{
                                                    return(
                                                        <li>
                                                            <div className="d-flex align-items-center">
                                                                <div className="col-md-2 col-sm-3 col-3 pl-2 d-flex">
                                                                    <span className="bp3-icon bp3-icon-lock">
                                                                        <img className="bp3-icon " src={data.logo}/>
                                                                    </span>
                                                                </div>
                                                                <div className="col-md-10 col-sm-9 col-9 pl-1 pr-0">
                                                                    <Checkbox
                                                                        Alignment="Alignment.RIGHT"
                                                                        label={data.text}
                                                                        value={data.value}
                                                                        onClick={ (e) => this.selectInclude(e) }

                                                                        checked={   
                                                                            this.state.searchData && this.state.searchData.filter_include ?
                                                                                this.state.searchData.filter_include.includes(""+data.value+"") ?
                                                                                    true :false     
                                                                            :
                                                                            false
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </li>
                                                    )
                                                })
                                                :
                                                ''
                                            }
                                        </div>
                                    </Collapse>
                                </div>
                            </div>

                            <Button
                                className="floating-menu bp3-button bp3-small hidden-xl hidden-lg hidden-md visible-sm visible-xs"
                                intent={Intent.PRIMARY}
                                onClick={() => this.toggleDrawer()}
                            >
                                <span className="bp3-icon bp3-icon-filter"></span>
                            </Button>


                        </React.Fragment>
                    
                }    
            </React.Fragment>
        )
    }
}

