import React from 'react';
import { Tab, Tabs } from "@blueprintjs/core";
import TopBar from '../../core/components/TopBar';
import Filter from './Filter';
import SearchBar from './SearchBar';
import PlaceList from './PlaceList';

export default class SearchData extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sourceSearchBoxList: null,
            sourceSearchDataList: null,
            activePanelOnly: false,
            animate: true,
            vertical: false,
            loadingData:true
        }
    }

    /*componentDidMount() {        
        this.setState({
            isPackageLoad:true
        })
        this.props.getSearchData(this.props.history.location.state.query) ;        
    }*/
    
    /*componentWillReceiveProps(nextProps) {
        
        if(this.state.isPackageLoad){ 
            this.setState({                            
                sourceSearchDataList: nextProps.sourceSearchDataList,
                isPackageLoad:false,
                loadingData:false
            })            
            this.props.getSearchBox();
        }else{
            this.setState({                
                sourceSearchBoxList: nextProps.sourceSearchBoxList,            
                loadingData:false
            })    
        }    
        console.log(nextProps,"pp");
    }*/
    componentDidMount() { //console.log(this.props,"props")
        window.scrollTo(0, 0)
        //this.props.getSearchBox();

        this.props.history.location.state 
            && this.props.history.location.state.query 
                && this.props.history.location.state.query.data ? 
        this.props.getSearchData(this.props.history.location.state.query.data)  
        : 
        this.props.getSearchData([]) 


/*

        this.props.history.location.state.query.data ?
            this.props.getSearchData(this.props.history.location.state.query.data) 
        :
            this.props.getSearchData(this.props.history.location.state.query.previous_page_data) */
    }
    
    SearchTours = (data) =>{
        
        let historyData = {};
        historyData['previous_page'] = '/SearchResult';
        if(data.Category == "Packages")
        {   
            var go = '/HotelDetails/'+ data.Name;
            historyData['current_page'] = '/HotelDetails/'+ data.Name;
            historyData['current_title'] = 'Package Detail';
            historyData['data'] = [];
            historyData['previous_page_data'] = this.props.history.location.state.query.data;
            
            this.props.history.push(go,{query:historyData});
        }else{
            this.setState({                              
                sourceSearchDataList: null
            })
            var go = '/SearchResult';
            historyData['current_page'] = '/SearchResult';
            historyData['current_title'] = 'Search Result';
            historyData['data'] = data;
            historyData['previous_page_data'] = this.props.history.location.state.query.data;
            this.props.getSearchData(historyData.data) ;
            //console.log(this.props.history,"nhis")
            //this.props.getSearchData(data);
        }
        
        //console.log(this.props,historyData,"console");
       //this.props.history.push(go,{query:historyData});
        
        /*if(data.Category == "Packages")
        {
            window.location.href = "/HotelDetails/" + data.Name;
        }else{  
            this.setState({                              
                sourceSearchDataList: null
            })
            //this.state.searchData.push(data)
            this.props.getSearchData(data);
            //this.props.history.push('/SearchResult',{query:data})
        }*/



        


        /*//console.log(data,"pp");
        if(data.Category == "Packages")
        {
            window.location.href = "/HotelDetails/" + data.Name;
        }else{  
            this.setState({                              
                sourceSearchDataList: null
            })
            //this.state.searchData.push(data)
            this.props.getSearchData(data);
            //this.props.history.push('/SearchResult',{query:data})
        }*/
    }

    componentWillReceiveProps(nextProps) {
        //console.log(nextProps,"nextProps");
        this.setState({                
            sourceSearchBoxList: nextProps.sourceSearchBoxList,
            sourceSearchDataList: nextProps.sourceSearchDataList,
            loadingData:false
        })        
        
    }

    render() {
        return (
            <React.Fragment>

                <div className="bg-white border-none">
                    <div className="container">
                        <TopBar historyTitle='Search Result' history = { this.props.history }/>
                    </div>
                </div>
                <div className="search_place">
                    <div className="container py-5 pt-s-0 ">
                        <div className="col-12 px-0">
                            <div className="row">
                                <div className="col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12 ">
                                    <div className="">

                                        {
                                            this.state.sourceSearchDataList ? 
                                            <Filter 
                                                sourceSearchDataList={this.state.sourceSearchDataList} 
                                                history={this.props.history} 
                                                SearchTours={(data)=>this.SearchTours(data)}
                                                searchData={this.props.sourceSearchInput}
                                            /> 
                                            : <div></div>
                                        }
                                    </div>
                                </div>
                                <div className="col-xl-9 col-lg-9 col-md-8 col-sm-12 col-12 px-s-0 px-sm-2 px-md-0 px-lg-0 px-xl-0 px-0">
                                    {
                                        this.state.sourceSearchBoxList ? 
                                        <SearchBar 
                                            sourceSearchBoxList={this.state.sourceSearchBoxList} 
                                            getCitys={(data,tripType)=>this.props.getCitys(data,tripType)}
                                            SearchTours={(data)=>this.SearchTours(data)}
                                        /> 
                                        : <div></div>
                                    }    
                                    {
                                        this.state.sourceSearchDataList ?
                                        //&& this.state.sourceSearchDataList.result && this.state.sourceSearchDataList.result.length ?
                                            <PlaceList 
                                            sourceSearchDataList={this.state.sourceSearchDataList} 
                                            SearchTours={(data)=>this.SearchTours(data)}
                                            />
                                        :
                                            <div></div>
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}