let initialState = {
    sourceSearchBoxList: null,
    sourceSearchDataList: null,
    sourceHotelDetailsList: null,
    sourceInquiryDetails: null,
    sourcePackageList: null,
    sourceSearchInput: null,
};

const HolidayPackage = (state = initialState, action) => { 
    switch (action.type) {
        case 'GET_SOURCE_SEARCH_BOX':
            return {...state, sourceSearchBoxList: action.sources}

        case 'GET_SOURCE_SEARCH_DATA'://console.log(action.input,"ss");
            return {...state, sourceSearchDataList: action.sources , sourceSearchInput:action.input}

        case 'GET_SOURCE_HOTEL_DETAILS':
            return {...state, sourceHotelDetailsList: action.sources}    
        case 'SET_INQUIRY_DETAILS':
            return {...state, sourceInquiryDetails: action.sources}

        case 'SET_PACKAGE_LIST':
            return {...state, sourcePackageList: action.sources}    

        default:
            return state
    }
};
export default HolidayPackage;