import React from 'react';
import SearchDataContainer from './container/SearchDataContainer';



export default class SearchResult extends React.Component{
    constructor(props){
        super(props);
        
        this.state = {
            activePanelOnly: false,
            animate: true,
            vertical: false
        };
    }

    render(){
        return(
            <React.Fragment>
                <main>
                    <SearchDataContainer history={this.props.history}/>
                </main>
            </React.Fragment>
        )
    }
}
