import React from 'react';
import { Tab, Tabs } from "@blueprintjs/core";
import TopBar from '../../core/components/TopBar';
import Photos from './Photos';
import Overview from './Overview';
import Inclusions from './Inclusions';
import DatePrice from './DatePrice';
import Cancellation from './Cancellation';
import PackageDetails from './PackageDetails';


import { } from "@blueprintjs/core";
import Loading from './loading';


export default class HotelDetails extends React.Component {

    constructor(props) { //console.log(props,"DEprops")
        super(props);
        this.state = {
            sourceHotelDetailsList: null,
            activePanelOnly: false,
            animate: true,
            vertical: false,
            loadingData:true,
            sourceInquiryDetails:null
        }

    }

    componentDidMount() {//console.log(this.props,"p1")
        window.scrollTo(0, 0)
        this.props.getHotelDetails(this.props.package_name);
    }
    
    componentWillReceiveProps(nextProps) {
        console.log(nextProps);
        this.setState({
            sourceHotelDetailsList: nextProps.sourceHotelDetailsList,
            loadingData:false
        })

        if(nextProps.sourceInquiryDetails) {
            this.setState({
                sourceInquiryDetails:nextProps.sourceInquiryDetails
            })
        }else{
            this.setState({
                sourceInquiryDetails:null
            })
        }

       // console.log(this.props)

    }

    setPackageEnquiry = (data) =>{

        this.props.setPackageEnquiry(data)
    }

    SetHolidayPackagePrice = (price) =>{
       const package_details = this.state.sourceHotelDetailsList.package_details;
       package_details.select_price=price;

       this.setState({
           package_details
       });

   }

    render() {
        return (
           
                <React.Fragment>
                <div className="bg-white border-none">
                    <div className="container">
                        <TopBar historyTitle='Hotel Detail' history = { this.props.history }/>
                    </div>
                </div>
                <section className="Hotel-Details">
                    <div className="container">
                        <div className="col-12">
                            <div className="row">
                                {
                                    this.state.sourceHotelDetailsList ? 
                                        <React.Fragment>
                                            <div className="col-xl-9 col-lg-9 col-md-8 col-sm-12 col-12">
                                                <div className="row heading">
                                                    <div className="border-theme col-12">
                                                        <h2>{this.state.sourceHotelDetailsList.package_name}</h2>
                                                    </div>
                                                </div>
                                                <div className="row Detail_parts mt-4">
                                                    <Photos image = {this.state.sourceHotelDetailsList.image}/>
                                                </div>
                                                <div className="row Detail_parts mt-4">
                                                    <Overview package_details = {this.state.sourceHotelDetailsList} />
                                                </div>
                                                 <div className="row Detail_parts mt-4">
                                                    <Inclusions package_details = {this.state.sourceHotelDetailsList} />
                                                </div>

                                                {/*{
                                                    this.state.sourceHotelDetailsList.package_details &&
                                                    this.state.sourceHotelDetailsList.package_details.price_details_calendar
                                                     ?
                                                    <div className="row Detail_parts mt-4">
                                                        <DatePrice 
                                                            package_details = {this.state.sourceHotelDetailsList.package_details} 
                                                            HolidayPackagePrice={(price)=>{this.SetHolidayPackagePrice(price)}}
                                                        />
                                                    </div>
                                                    :
                                                    ''
                                                }*/}
                                                
                                                <div className="row Detail_parts mt-4">
                                                        <Cancellation package_details = {this.state.sourceHotelDetailsList}/>
                                                </div>

                                            </div>
                                            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-7 col-12 px-s-0 pr-md-0 Package_Details">
                                            <PackageDetails package_details = {this.state.sourceHotelDetailsList}
                                                            setPackageEnquiry={(data)=>this.setPackageEnquiry(data)}
                                                            sourceInquiryDetails={this.state.sourceInquiryDetails}
                                            />
                                            </div>
                                        </React.Fragment>
                                        :
                                        <Loading/>
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>




            
        );
    }
}