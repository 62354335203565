import React, {Component} from 'react';
import { Card } from "@blueprintjs/core";

export default class Loading extends React.Component{
    render(){
        return(
            <div className="col-12">
                <div className="row">
                    <div className="col-xl-9 col-lg-9 col-md-8 col-sm-8 col-12">
                        <div className="row">
                            <div className="col-12 mb-4">
                                <Card className='p-1 border-0 shadow-none' style={{boxShadow: "none"}}>
                                    <center>
                                        <div className="bp3-skeleton" style={{height: "50px"}} />
                                    </center>
                                </Card>
                            </div>
                            <div className="col-12 mb-4">
                                <Card className='p-1 border-0 shadow-none' style={{boxShadow: "none"}}>
                                    <center>
                                        <div className="bp3-skeleton mb-2" style={{height: "50px"}} />
                                        <div className="bp3-skeleton" style={{height: "200px"}} />
                                        <br/>
                                        <div className="bp3-skeleton pull-left" style={{height: "25px",width:'70%'}} />
                                        <div className="bp3-skeleton pull-right" style={{height: "25px",width:'20%'}} />
                                        <br/>
                                        <br/>
                                        <div className="bp3-skeleton" style={{height: "25px",width:'100%'}} />
                                    </center>

                                </Card>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-12 mb-4">
                                <Card className='p-1 border-0 shadow-none' style={{boxShadow: "none"}}>
                                    <center>
                                        <div className="bp3-skeleton mb-2" style={{height: "50px"}} />
                                        <div className="bp3-skeleton" style={{height: "200px"}} />
                                        <br/>
                                        <div className="bp3-skeleton pull-left" style={{height: "25px",width:'70%'}} />
                                        <div className="bp3-skeleton pull-right" style={{height: "25px",width:'20%'}} />
                                        <br/>
                                        <br/>
                                        <div className="bp3-skeleton" style={{height: "25px",width:'100%'}} />
                                    </center>

                                </Card>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-3 col-lg-3 col-md-4 col-sm-4 col-12 px-s-0 pr-md-0 Package_Details">
                        <div className="row">
                            <div className="col-12 mb-4">
                                <Card className='p-1 border-0 shadow-none' style={{boxShadow: "none"}}>
                                    <center>
                                        <div className="bp3-skeleton pull-left" style={{height: "45px",width:'25%'}} />
                                        <div className="bp3-skeleton pull-right" style={{height: "45px",width:'70%'}} />
                                        <br/><br/><br/><br/>
                                        <div className="bp3-skeleton pull-left" style={{height: "45px",width:'25%'}} />
                                        <div className="bp3-skeleton pull-right" style={{height: "45px",width:'70%'}} />
                                        <br/><br/><br/><br/>
                                        <div className="bp3-skeleton pull-left" style={{height: "45px",width:'25%'}} />
                                        <div className="bp3-skeleton pull-right" style={{height: "45px",width:'70%'}} />
                                        <br/><br/><br/><br/>
                                        <div className="bp3-skeleton" style={{height: "25px",width:'100%'}} />
                                        <br/>
                                        <div className="bp3-skeleton pull-left" style={{height: "45px",width:'25%'}} />
                                        <div className="bp3-skeleton pull-right" style={{height: "45px",width:'70%'}} />
                                        <br/><br/><br/><br/>
                                        <div className="bp3-skeleton pull-left" style={{height: "45px",width:'25%'}} />
                                        <div className="bp3-skeleton pull-right" style={{height: "45px",width:'70%'}} />
                                        <br/><br/><br/><br/>
                                        <div className="bp3-skeleton" style={{height: "25px",width:'100%'}} />
                                        <br/>
                                    </center>

                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
