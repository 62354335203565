import React, {Component} from 'react';
import { Card } from "@blueprintjs/core";

export default class Loading extends React.Component{
    render(){
        return(
            <div className="col-12 mt-2">
                <div className="row mt-4">
                    <div className="col-md-4 col-xl-4 col-lg-4 col-sm-6 col-12 mb-4">
                        <Card className='p-1 border-0 shadow-none' style={{boxShadow: "none"}}>
                            <center>
                                <div className="bp3-skeleton" style={{height: "200px"}} />
                                <br/>
                                <div className="bp3-skeleton pull-left" style={{height: "25px",width:'70%'}} />
                                <div className="bp3-skeleton pull-right" style={{height: "25px",width:'20%'}} />
                                <br/>
                                <br/>
                                <div className="bp3-skeleton" style={{height: "25px",width:'100%'}} />
                            </center>

                        </Card>
                    </div>
                    <div className="col-md-4 col-xl-4 col-lg-4 col-sm-6 col-12 mb-4">
                        <Card className='p-1 border-0 shadow-none' style={{boxShadow: "none"}}>
                            <center>
                                <div className="bp3-skeleton" style={{height: "200px"}} />
                                <br/>
                                <div className="bp3-skeleton pull-left" style={{height: "25px",width:'70%'}} />
                                <div className="bp3-skeleton pull-right" style={{height: "25px",width:'20%'}} />
                                <br/>
                                <br/>
                                <div className="bp3-skeleton" style={{height: "25px",width:'100%'}} />
                            </center>

                        </Card>
                    </div>
                    <div className="col-md-4 col-xl-4 col-lg-4 col-sm-6 col-12 mb-4">
                        <Card className='p-1 border-0 shadow-none' style={{boxShadow: "none"}}>
                            <center>
                                <div className="bp3-skeleton" style={{height: "200px"}} />
                                <br/>
                                <div className="bp3-skeleton pull-left" style={{height: "25px",width:'70%'}} />
                                <div className="bp3-skeleton pull-right" style={{height: "25px",width:'20%'}} />
                                <br/>
                                <br/>
                                <div className="bp3-skeleton" style={{height: "25px",width:'100%'}} />
                            </center>

                        </Card>
                    </div>
                    <div className="col-md-4 col-xl-4 col-lg-4 col-sm-6 col-12 mb-4">
                        <Card className='p-1 border-0 shadow-none' style={{boxShadow: "none"}}>
                            <center>
                                <div className="bp3-skeleton" style={{height: "200px"}} />
                                <br/>
                                <div className="bp3-skeleton pull-left" style={{height: "25px",width:'70%'}} />
                                <div className="bp3-skeleton pull-right" style={{height: "25px",width:'20%'}} />
                                <br/>
                                <br/>
                                <div className="bp3-skeleton" style={{height: "25px",width:'100%'}} />
                            </center>

                        </Card>
                    </div>
                    <div className="col-md-4 col-xl-4 col-lg-4 col-sm-6 col-12 mb-4">
                        <Card className='p-1 border-0 shadow-none' style={{boxShadow: "none"}}>
                            <center>
                                <div className="bp3-skeleton" style={{height: "200px"}} />
                                <br/>
                                <div className="bp3-skeleton pull-left" style={{height: "25px",width:'70%'}} />
                                <div className="bp3-skeleton pull-right" style={{height: "25px",width:'20%'}} />
                                <br/>
                                <br/>
                                <div className="bp3-skeleton" style={{height: "25px",width:'100%'}} />
                            </center>

                        </Card>
                    </div>
                    <div className="col-md-4 col-xl-4 col-lg-4 col-sm-6 col-12 mb-4">
                        <Card className='p-1 border-0 shadow-none' style={{boxShadow: "none"}}>
                            <center>
                                <div className="bp3-skeleton" style={{height: "200px"}} />
                                <br/>
                                <div className="bp3-skeleton pull-left" style={{height: "25px",width:'70%'}} />
                                <div className="bp3-skeleton pull-right" style={{height: "25px",width:'20%'}} />
                                <br/>
                                <br/>
                                <div className="bp3-skeleton" style={{height: "25px",width:'100%'}} />
                            </center>

                        </Card>
                    </div>
                    <div className="col-md-4 col-xl-4 col-lg-4 col-sm-6 col-12 mb-4">
                        <Card className='p-1 border-0 shadow-none' style={{boxShadow: "none"}}>
                            <center>
                                <div className="bp3-skeleton" style={{height: "200px"}} />
                                <br/>
                                <div className="bp3-skeleton pull-left" style={{height: "25px",width:'70%'}} />
                                <div className="bp3-skeleton pull-right" style={{height: "25px",width:'20%'}} />
                                <br/>
                                <br/>
                                <div className="bp3-skeleton" style={{height: "25px",width:'100%'}} />
                            </center>

                        </Card>
                    </div>
                    <div className="col-md-4 col-xl-4 col-lg-4 col-sm-6 col-12 mb-4">
                        <Card className='p-1 border-0 shadow-none' style={{boxShadow: "none"}}>
                            <center>
                                <div className="bp3-skeleton" style={{height: "200px"}} />
                                <br/>
                                <div className="bp3-skeleton pull-left" style={{height: "25px",width:'70%'}} />
                                <div className="bp3-skeleton pull-right" style={{height: "25px",width:'20%'}} />
                                <br/>
                                <br/>
                                <div className="bp3-skeleton" style={{height: "25px",width:'100%'}} />
                            </center>

                        </Card>
                    </div>
                    <div className="col-md-4 col-xl-4 col-lg-4 col-sm-6 col-12 mb-4">
                        <Card className='p-1 border-0 shadow-none' style={{boxShadow: "none"}}>
                            <center>
                                <div className="bp3-skeleton" style={{height: "200px"}} />
                                <br/>
                                <div className="bp3-skeleton pull-left" style={{height: "25px",width:'70%'}} />
                                <div className="bp3-skeleton pull-right" style={{height: "25px",width:'20%'}} />
                                <br/>
                                <br/>
                                <div className="bp3-skeleton" style={{height: "25px",width:'100%'}} />
                            </center>

                        </Card>
                    </div>
                </div>
            </div>
        )
    }
}
