import {connect} from 'react-redux';
import ToursPanelComponent from "../components/ToursPanel";

import {getSourceCitys} from '../../core/actions/action';
import {sourceCityList} from '../../core/actions';

const mapStateToProps = state => {
    return{
        
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getCitys: (input,tripType) => {//alert("3");
            return getSourceCitys(input,tripType);
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ToursPanelComponent);
