import React from 'react';
import {Header, Footer, ScrollTop, Auth} from 'gg-react-utilities';

export default class HeaderFooter extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isHeader:'Web',
        }
    }

    /*componentDidMount(){
    
    }*/

    render(){

        let headerType='dark';

        return (
           <React.Fragment>
              <Header headerType={headerType} />
               {this.props.children}
              <Footer/>
              <ScrollTop/>
                        
              
           </React.Fragment>
        )
    }
}