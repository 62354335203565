import React, { Component } from 'react';
import { Button, Intent, Collapse } from "@blueprintjs/core";
import {Link} from "react-router-dom";
import Select from 'react-select';
import { Month } from '../../core/components/data';
import CreatableSelect from 'react-select/lib/Creatable';

import {ssoInstance} from 'gg-react-utilities';
import Config from '../../Config';
import {Async} from 'react-select';
import _ from "lodash";
import debounce from 'lodash.debounce';

export default class SearchBar extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            isOpen: false,
            keepChildrenMounted: false,

            SearchType:2,
            region:null,
            region_id:null,
            country:null,
            country_id:null,
            city:null,
            city_id:null,
            filterData:null,
            duration_id:null,
            budget_id:null,
            inclusion_id:null,
            category_id:null,
            month_id:null,
            sourceCityList: [],
            sourceCity:null
        }
    }

    componentDidMount(props){

        this.setState({
            sourceSearchBoxList: this.props.sourceSearchBoxList
        })
    }

    handleClick = () => {
        this.setState({
           isOpen: !this.state.isOpen
        });
    }

    handleSearchRegion = (e) =>{        
        
        ssoInstance.post(Config.API_URL + 'getCountry',{region_id: e.value} ).then((res) =>{
            if (res.status === 200) {
                //console.log(res.data)
                if (res.data.status === true) {
                    this.setState({
                        region_id:e.value,
                        country:res.data.data,
                        city:null
                    })
                }
            }   
        }) 
    }

    handleSearchCountry = (e) =>{        
        
        ssoInstance.post(Config.API_URL + 'getCityByCountry',{country_id: e.value} ).then((res) =>{
            if (res.status === 200) {
                
                if (res.data.status === true) {
                    this.setState({
                        country_id:e.value,
                        city:res.data.data,
                    })
                }
            }   
        }) 
    }

    /*getPlaces = (input,callback) => {
       
       this.props.getCitys(input,this.state.SearchType)
           .then((res) => {                
               if (res.status === 200) {
                   const response = res.data;                   
                   if (response.status === true) {
                       let holiday = [];
                       response.data.map((resp) => {                        
                            holiday.push({
                               Category: resp.Category,
                               ItemName: resp.ItemName,
                               CityID: resp.CityID,
                               StateID: resp.StateID,
                               CountryID: resp.CountryID,
                               Name: resp.Name,
                               value: resp.Category,
                               label: resp.ItemName
                           });
                           return true;
                       });

                        this.setState({
                            sourceCityList:holiday
                        })
                        //console.log(this.state.sourceCityList)
                        callback(holiday);
                   }
               }

               //callback(this.state.sourceCityList);
           });

    }*/

    handleAdvanceSearch = () => {   
        
        if(this.state.sourceCity){
        
            this.props.SearchTours(this.state.sourceCity);
        }else{
         
            this.state.filterData = {};
            if(this.state.region_id){ this.state.filterData['RegionId']=this.state.region_id;  }
            if(this.state.country_id){ this.state.filterData['CountryId']=this.state.country_id;  }
            if(this.state.city_id){ this.state.filterData['CityId']=this.state.city_id;  }

            if(this.state.duration_id){ this.state.filterData['Duration']=this.state.duration_id;  }
            if(this.state.budget_id){ this.state.filterData['Budget']=this.state.budget_id;  }
            if(this.state.inclusion_id){ this.state.filterData['Inclusion']=this.state.inclusion_id;  }
            if(this.state.category_id){ this.state.filterData['PackageCategory']=this.state.category_id;  }
            if(this.state.month_id){ this.state.filterData['Month']=this.state.month_id;  }

            this.props.SearchTours(this.state.filterData);
        }
    }

    async getOptions(inputValue) { 
        
      if (!inputValue) {
        return [];
      }

      return ssoInstance.post(Config.API_URL + 'getCity',{"searchText" : inputValue,"tripType" : 2 })
                        .then(
                            (res)=> {
                              if (res.status === 200) {
                                const response = res.data; 

                                if (response.status === true) {
                                  let holiday = [];
                                  response.data.map((resp) => {
                                      holiday.push({
                                         Category: resp.Category,
                                         ItemName: resp.ItemName,
                                         CityID: resp.CityID,
                                         StateID: resp.StateID,
                                         CountryID: resp.CountryID,
                                         Name: resp.Name,
                                         value: resp.Category,
                                         label: resp.ItemName
                                     });
                                  })
                                  return holiday;
                                }
                              }
                            } 
                        );
    }
    
    render(){
        //let placeSearch = _.debounce( (input, callback) => {this.getPlaces(input, callback)}, 100);
        return(

            <React.Fragment>
                {
                    this.state.sourceSearchBoxList ? 
                        <React.Fragment>
                            <div className="col-12 mt-s-4 px-s-2 mb-4">
                                <div className="border-theme SearchBar">
                                    <div className="col-12 py-4 px-1 pb-s-5">
                                        <div className="">
                                            <div className="d-sm-block d-md-flex d-lg-flex d-xl-flex d-md-flex justify-content-between">
                                                <div className="col-md-9 col-12 bp3-input-group">
                                                    <div className="bp3-input-group">
                                                        
                                                        {/*<Async
                                                            //multi={false}
                                                            value={this.state.sourceCity}
                                                            isLoading={true}
                                                            onChange={(value)=>
                                                                {
                                                                    this.setState({
                                                                        sourceCity:value
                                                                    })    
                                                                }
                                                            }

                                                            valueKey="CityID"
                                                            labelKey="ItemName"
                                                            Key="CountryID"
                                                            loadOptions={(input, callback) => placeSearch(input, callback)}
                                                            placeholder={''}
                                                            searchPromptText={"Please enter 3 or More characters"}
                                                            backspaceRemoves={true}
                                                            defaultOptions
                                                        />*/}

                                                        <Async
                                                            isClearable
                                                            multi={false}
                                                            value={this.state.sourceCity}
                                                            isLoading={true}
                                                            onChange={(value)=>
                                                              {   
                                                                this.setState({
                                                                  sourceCity:value
                                                                })    
                                                              }
                                                            }
                                                            valueKey="CityID"
                                                            labelKey="ItemName"
                                                            Key="CountryID"
                                                            loadOptions={this.getOptions}
                                                            placeholder={'Where do you want to go?'}
                                                            searchPromptText={"Please enter 3 or More characters"}
                                                            backspaceRemoves={true}
                                                            defaultOptions
                                                        />



                                                    </div>
                                                </div>
                                                <div className="col-md-3 col-12 mt-s-3 text-center">
                                                    <Button
                                                        type="Submit"
                                                        text="Search"
                                                        className="bp3-button search_btn rounded"
                                                        intent={Intent.PRIMARY}
                                                        onClick={ ()=>this.handleAdvanceSearch()  }
                                                    />

                                                    <div className="mb-1 mt-2">
                                                        <Button
                                                            onClick={this.handleClick}
                                                            className="text-nowrap pull-right pr-3 bp3-minimal mr-s-2"
                                                            text="Customise Search">
                                                            {this.state.isOpen}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                            <Collapse isOpen={this.state.isOpen} keepChildrenMounted={this.state.keepChildrenMounted}>
                                                <div className="col-lg-9 col-md-9 col-12 mt-s-5 px-md-0">
                                                    
                                                    <div className="row w-100 m-0">

                                                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mt-2 mt-s-3 bp3-input-group px-s-0">
                                                            {
                                                                this.state.sourceSearchBoxList.region ?
                                                                    <CreatableSelect
                                                                        //isClearable
                                                                        options={
                                                                            this.state.sourceSearchBoxList.region.map((data,i)=>{

                                                                                return({value:data.region_id,label:data.region_name})
                                                                            })
                                                                        }
                                                                        onChange={(e) => this.handleSearchRegion(e)}
                                                                        placeholder="Search By Area"
                                                                        clssName="text-left" />
                                                                    :
                                                                    <div></div>
                                                            }
                                                        </div>


                                                        {
                                                            this.state.country ? 

                                                                <React.Fragment>
                                                                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mt-2 mt-s-3 bp3-input-group px-s-0">
                                                                        {
                                                                            <CreatableSelect
                                                                               // isClearable
                                                                                options={
                                                                                     this.state.country.map((data,i)=>{
                                                                                        return({value:data.country_id,label:data.country_name})
                                                                                     })
                                                                                }
                                                                                
                                                                                onChange={(e) => this.handleSearchCountry(e)} 

                                                                                placeholder="Search By Country"
                                                                                clssName="text-left" 
                                                                            />
                                                                        }
                                                                    </div>    

                                                                </React.Fragment>
                                                            :
                                                            ''    
                                                        }

                                                        {
                                                            this.state.city ? 

                                                                <React.Fragment>
                                                                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mt-2 mt-s-3 bp3-input-group px-s-0">
                                                                        {
                                                                            <CreatableSelect
                                                                                //isClearable
                                                                                options={
                                                                                     this.state.city.map((data,i)=>{
                                                                                        return({value:data.city_id,label:data.city_name})
                                                                                     })
                                                                                }
                                                                                
                                                                                onChange={
                                                                                    (e) => {

                                                                                        if(e.value == 0){
                                                                                            this.setState({
                                                                                                city_id:null,
                                                                                            })
                                                                                        }else{
                                                                                            this.setState({
                                                                                                city_id:e.value,
                                                                                            })
                                                                                        }
                                                                                    }
                                                                                }

                                                                                placeholder="Search By City"
                                                                                clssName="text-left" 
                                                                            />
                                                                        }
                                                                    </div>    
                                                                </React.Fragment>
                                                            :
                                                            ''    
                                                        }

                                                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mt-2 mt-s-3 bp3-input-group px-s-0">
                                                            {
                                                                this.state.sourceSearchBoxList.duration ?
                                                                    <CreatableSelect
                                                                        //isClearable
                                                                        options={
                                                                            this.state.sourceSearchBoxList.duration.map((data,i)=>{
                                                                                return({value:data.value,label:data.text})
                                                                            })
                                                                        }
                                                                          
                                                                        onChange={
                                                                            
                                                                            (e) => {

                                                                                if(e.value == 0){
                                                                                    this.setState({
                                                                                        duration_id:null,
                                                                                    })
                                                                                }else{
                                                                                    this.setState({
                                                                                        duration_id:e.value,
                                                                                    })
                                                                                }
                                                                            }
                                                                        }        

                                                                        placeholder="Search By Duration"
                                                                        clssName="text-left" />
                                                                    :
                                                                    <div></div>
                                                            }
                                                        </div>

                                                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mt-2 mt-s-3 bp3-input-group px-s-0">
                                                            {
                                                                this.state.sourceSearchBoxList.budget ?
                                                                    <CreatableSelect
                                                                        //isClearable
                                                                        options={
                                                                            this.state.sourceSearchBoxList.budget.map((data,i)=>{
                                                                                return({value:data.value,label:data.text})
                                                                            })
                                                                        }

                                                                        onChange={
                                                                            
                                                                            (e) => {

                                                                                if(e.value == 0){
                                                                                    this.setState({
                                                                                        budget_id:null,
                                                                                    })
                                                                                }else{
                                                                                    this.setState({
                                                                                        budget_id:e.value,
                                                                                    })
                                                                                }
                                                                            }
                                                                        } 

                                                                        placeholder="Search By Budget"
                                                                        clssName="text-left" />
                                                                    :
                                                                    <div></div>
                                                            }
                                                        </div>


                                                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mt-2 mt-s-3 bp3-input-group px-s-0">
                                                            {
                                                                this.state.sourceSearchBoxList.inclusion ?
                                                                    <CreatableSelect
                                                                        //isClearable
                                                                        options={
                                                                            this.state.sourceSearchBoxList.inclusion.map((data,i)=>{
                                                                                return({value:data.value,label:data.text})
                                                                            })
                                                                        }
                                                                        
                                                                        onChange={
                                                                            
                                                                            (e) => {

                                                                                if(e.value == 0){
                                                                                    this.setState({
                                                                                        inclusion_id:null,
                                                                                    })
                                                                                }else{
                                                                                    this.setState({
                                                                                        inclusion_id:e.value,
                                                                                    })
                                                                                }
                                                                            }
                                                                        }         
                                                                        placeholder="Search By Inclusion"
                                                                        clssName="text-left" />
                                                                    :
                                                                    <div></div>
                                                            }
                                                        </div>

                                                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mt-2 mt-s-3 bp3-input-group px-s-0">
                                                            {
                                                                this.state.sourceSearchBoxList.category ?
                                                                    <CreatableSelect
                                                                        //isClearable
                                                                        options={
                                                                            this.state.sourceSearchBoxList.category.map((data,i)=>{
                                                                                return({value:data.value,label:data.text})
                                                                            })
                                                                        }
                                                                        
                                                                        onChange={
                                                                            
                                                                            (e) => {

                                                                                if(e.value == 0){
                                                                                    this.setState({
                                                                                        category_id:null,
                                                                                    })
                                                                                }else{
                                                                                    this.setState({
                                                                                        category_id:e.value,
                                                                                    })
                                                                                }
                                                                            }
                                                                        }        
                                                                        placeholder="Search By Interest"
                                                                        clssName="text-left" />
                                                                    :
                                                                    <div></div>
                                                            }
                                                        </div>

                                                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mt-2 mt-s-3 bp3-input-group px-s-0">
                                                            {
                                                                this.state.sourceSearchBoxList.month ?
                                                                    <CreatableSelect
                                                                        //isClearable
                                                                        options={
                                                                            this.state.sourceSearchBoxList.month.map((data,i)=>{
                                                                                return({value:data.value,label:data.text})
                                                                            })
                                                                        }
                                                                        onChange={
                                                                            
                                                                            (e) => {

                                                                                if(e.value == 0){
                                                                                    this.setState({
                                                                                        month_id:null,
                                                                                    })
                                                                                }else{
                                                                                    this.setState({
                                                                                        month_id:e.value,
                                                                                    })
                                                                                }
                                                                            }
                                                                        }        
                                                                        placeholder="Search By Month"
                                                                        clssName="text-left" />
                                                                    :
                                                                    <div></div>
                                                            }
                                                        </div>

                                                    </div>
                                                </div>

                                            </Collapse>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    :
                    <div></div>    
                }
            </React.Fragment>

        )
    }
}