import React, { Component } from 'react';
import { Button, Intent, Tag, Icon ,Card, Elevation} from "@blueprintjs/core";
import {Link} from "react-router-dom";
import Loading from "./loading";
import defaultImage from "../../core/images/package_detail.jpg";
import {Helper} from 'gg-react-utilities';

export default class SearchBar extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            loadingData:true,
        }
        
    }

    componentDidMount(props) {
           
        if(this.props.sourceSearchDataList.result && this.props.sourceSearchDataList.result.length > 0){
            this.setState({
                sourceSearchDataList: this.props.sourceSearchDataList
            })
        }
    }
    

    render(){
        return(
            <React.Fragment>
                <div className="PlaceList pt-2">
                    <div className="col-12">
                        <div className="row place-category">
                            {
                                this.state.sourceSearchDataList ?
                                    <React.Fragment>

                                        {
                                            this.state.sourceSearchDataList.result.map((data,i)=> {
                                                return(
                                                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mb-4 mb-s-0">
                                                        <div className="place">
                                                            {/*<Link to={"/HotelDetails/"+data.SearchUrl} title={data.PackageName}>*/}
                                                            <div title={data.PackageName}>
                                                                <div className="place-img">
                                                                    <img 
                                                                        src={data.BigImage}
                                                                        className="img-fluid"
                                                                        onError={
                                                                            (e)=>{
                                                                                e.target.onerror = null; 
                                                                                e.target.src=defaultImage 
                                                                            }
                                                                        } 
                                                                        alt=""/>   
                                                                </div>

                                                                <div className="p-2">
                                                                    <h4 className="place-name">{data.PackageName}</h4>
                                                                    {
                                                                        (data.cityNames_arr && data.cityNames_arr.length) ?
                                                                            <h6 className="place-type">
                                                                                {
                                                                                    data.cityNames_arr.map((cna,c)=>{
                                                                                        var p = "";
                                                                                        var p = p + cna.text +"("+ cna.count +")" ;
                                                                                        return(<span>{p}</span>)
                                                                                    })

                                                                                }

                                                                            </h6>
                                                                        :
                                                                            <h6 className="place-type"></h6>
                                                                    }

                                                                </div>

                                                                <div className="px-0 text-left d-flex">
                                                                    <div>
                                                                        <Tag
                                                                            intent={Intent.WARNING}
                                                                            className="rounded-0"
                                                                        >
                                                                            Include
                                                                        </Tag>
                                                                        <span className="place-days">{data.Nights} Nights / {data.Days} Days</span>
                                                                    </div>
                                                                </div>

                                                                <div className="p-2 text-right">
                                                                    
                                                                    {
                                                                        data.Price && data.Price > 0 ?

                                                                            <h3 className="place-price bold">
                                                                                <span className="starting-from mr-2">Starting From</span> 
                                                                                { Helper.formatMoney(data.Price) }
                                                                            </h3>
                                                                        :
                                                                            <h3 className="place-price bold">
                                                                                Price On Request
                                                                            </h3>   
                                                                    }
                                                                </div>


                                                                <div className="pb-3 px-3 text-center">
                                                                    {
                                                                        (data.include && data.include.length) ?
                                                                            <div className="d-flex align-items-center justify-content-center py-2 border-top border-bottom">
                                                                                {
                                                                                    data.include.map((cna,c)=>{
                                                                                        return(<img src={cna.include_logo} className="mx-2 img-icon" title={cna.include_text}/>)
                                                                                    })
                                                                                }
                                                                            </div>
                                                                            :
                                                                            ""
                                                                    }
                                                                    
                                                                    <div className="py-2 d-flex justify-content-between">
                                                                        <h6 className="place-valid">Valid From</h6>
                                                                        <h6 className="valid-from">{data.ValidityFrom} - {data.ValidityTo}</h6>
                                                                    </div>
                                                                    <Button
                                                                        text="Explore Now"
                                                                        intent={Intent.PRIMARY}
                                                                        className="btn-explore d-block w-100 mt-1"
                                                                        onClick={ ()=>{ 

                                                                            let datasend = {};
                                                                            datasend['Category'] = 'Packages';
                                                                            datasend['Name'] = data.SearchUrl;
                                                                            this.props.SearchTours(datasend) 
                                                                        } }
                                                                    />
                                                                </div>
                                                            </div>
                                                            {/*</Link>*/}
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </React.Fragment>
                                :
                                    
                                <div className="PlaceList pt-2">
                                <div className="col-12">

                                        <h2>No tours available for selected location!</h2>
                                     
                                    </div>    
                                </div>    
                                    
                            }
                        </div>
                    </div>
                </div>
            </React.Fragment>

        )
    }
}