export const sourceSearchBox = (sources) => {//alert("6")
    return {
        type: 'GET_SOURCE_SEARCH_BOX',
        sources: sources
    }
};

export const sourceSearchData = (sources,input) => {//alert("6")
    return {
        type: 'GET_SOURCE_SEARCH_DATA',
        sources: sources,
        input: input
    }
};

export const sourceHotelDetails = (sources) => {//alert("6")
    return {
        type: 'GET_SOURCE_HOTEL_DETAILS',
        sources: sources
    }
};
export const sourceInquiryDetails = (sources) => {//alert("6")
    return {
        type: 'SET_INQUIRY_DETAILS',
        sources: sources
    }
};
export const sourcePackage = (sources) => { //console.log(sources);
    return {
        type: 'SET_PACKAGE_LIST',
        sources: sources
    }
};