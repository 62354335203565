import {connect} from 'react-redux';
import SearchData from "../components/SearchData";

import {getSourceSearchBox,getSourceSearchData,getSourceCitys} from '../../core/actions/action';
import {sourceSearchBox,sourceSearchData} from '../../core/actions';

const mapStateToProps = state => { 
    return{
        sourceSearchBoxList:state.HolidayPackage.sourceSearchBoxList,
        sourceSearchInput:state.HolidayPackage.sourceSearchInput,
        sourceSearchDataList:state.HolidayPackage.sourceSearchDataList
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getSearchData: (input) => {//console.log(input,"s1");
            return getSourceSearchData(input)
                .then(res => { //console.log(input,res.data,"s1");
                    const sources = res.data;
                    if (sources.status === true) {
                        dispatch(sourceSearchData(sources.data,input));
                    } else {
                        dispatch(sourceSearchData([],input));
                    }
                })
                .catch(error => {
                    const sources = error;
                    //console.log(error, "Search Result Container");
                });
        },

        getSearchBox: (input) => {//alert("3");
            return getSourceSearchBox(input)
                .then(res => { //alert("5")
                    const sources = res.data;
                    if (sources.status === true) {
                        dispatch(sourceSearchBox(sources.data));
                    } else {
                        dispatch(sourceSearchBox([]));
                    }
                })
                .catch(error => {
                    const sources = error;
                   // console.log(error, "Main Container");
                });
        },

        getCitys: (input,tripType) => {//alert("3");
            return getSourceCitys(input,tripType);
        }


    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SearchData);
