import {connect} from 'react-redux';
import MainComponent from "../components/Main";

import {getSourceSearchBox, getSourcePackage, getSourceGGHolidayPackages} from '../../core/actions/action';
import {sourceSearchBox,sourcePackage} from '../../core/actions';

const mapStateToProps = state => {
    return{
        sourcePackageList:state.HolidayPackage.sourcePackageList
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getPackageList: () => {
            return getSourceGGHolidayPackages()
                .then(res => {
                    const sources = res.data;
                    console.log(sources);
                    if (sources.status === true) {
                        dispatch(sourcePackage(sources.data));
                    } else {
                        dispatch(sourcePackage([]));
                    }
                })
                .catch(error => {
                    const sources = error;
                });
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(MainComponent);
