import React, { Component } from 'react';
import { Card, Elevation } from "@blueprintjs/core";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import defaultImage from "../../core/images/package_detail.jpg";
export default class Photos extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            imageData:null,
        }
    }

    componentDidMount() {

        this.setState({
            imageData: this.props.image
        })

        /*setTimeout(() => {
            this.setState({
                loadingData:false
            });
        }, 1000);*/
    }


    render(){
       
        return(
            <React.Fragment>
                <Card interactive={false} elevation={Elevation.TWO} className="col-12">
                    <div className="card-header">
                        <h2>Photos</h2>
                    </div>
                    <div className="card-body mt-4 px-s-2">
                        <ImageGallery
                            Swipe={false}
                            /*items={
                                this.state.imageData ? this.state.imageData.map( (data,i)=>{
                                        return({original:data.BigImage,thumbnail:data.BigImage})
                                    } ):[]
                            }*/
                            items={
                                this.state.imageData ? [{original:this.state.imageData,thumbnail:this.state.imageData}] : []
                            }
                            defaultImage={ defaultImage }
                            lazyLoad={true}
                            showIndex={false}
                            showBullets={false}
                            slideInterval="5000"
                            useKeyboardArrows={false}
                            showPlayButton={false}
                            className="thumbnails-horizontal"
                        />
                    </div>
                </Card>
            </React.Fragment>
        )
    }
}