import React, { Component } from 'react';
import { } from "@blueprintjs/core";
import HotelDetailsContainer from './container/HotelDetailsContainer';

export default class HotelDetails extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            loadingData: true,
            sourceHotelDetailsList:null
        }
    }

    render(){
        return(
            <React.Fragment>
                <main>
                    <HotelDetailsContainer package_name={this.props.match.params.package_name} history={this.props.history}/>
                </main>
            </React.Fragment>
        )
    }
}