import {connect} from 'react-redux';
import HotelDetails from "../components/HotelDetails";
import {Intent, Position,Toaster} from "@blueprintjs/core";
import {
    getSourceGGHolidayPackagesDetails,
    getSourceHotelDetails,
    setGGHolidayInquiryDetails,
    setInquiryDetails
} from '../../core/actions/action';
import {sourceHotelDetails,sourceInquiryDetails} from '../../core/actions';

const mapStateToProps = state => {
    return{
        sourceHotelDetailsList:state.HolidayPackage.sourceHotelDetailsList,
        sourceInquiryDetails:state.HolidayPackage.sourceInquiryDetails
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getHotelDetails: (input) => {
            return getSourceGGHolidayPackagesDetails(input)
                .then(res => {
                    const sources = res.data;
                    if (sources.status === true) {
                        dispatch(sourceHotelDetails(sources.data));
                    } else {
                        dispatch(sourceHotelDetails([]));
                    }
                })
                .catch(error => {//alert("6")
                    const sources = error;
                });
        },
        setPackageEnquiry: (input) => { //alert(input);
            return setGGHolidayInquiryDetails(input)
                .then(res => {
                    const sources = res.data;
                    if (sources.status === true) {
                        Toaster.create({
                            position: Position.RIGHT,
                        }).show({message: sources.message, intent: Intent.SUCCESS});
                        dispatch(sourceInquiryDetails(sources));
                    } else {
                        Toaster.create({
                            position: Position.RIGHT,
                        }).show({message: sources.message, intent: Intent.DANGER});
                        dispatch(sourceInquiryDetails([]));
                    }
                })
                .catch(error => {//alert("6")
                    const sources = error;
                });
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(HotelDetails);
