import React, { Component } from 'react';
import {Card, Elevation, Button, Icon, Intent, Dialog, Classes, Position,Toaster} from "@blueprintjs/core";
import { DateInput } from "@blueprintjs/datetime";
import CreatableSelect from 'react-select/lib/Creatable';
import { TimeHour, TimeMinute } from '../../core/components/data';
import {isString} from 'util';
import {Auth} from 'gg-react-utilities';
import moment from 'moment';
import defaultImage from "../../core/images/package_detail.jpg";
import {Helper} from 'gg-react-utilities';

export default class PackageDetails extends React.Component{
    
    constructor(props){
        super(props);

        this.state = {
            package_details:null,
            ModalInquiry: false,
            UserName:'',
            UserMobile:'',
            UserEmail:'',
            city:'',
            State:'',
            sourceInquiryDetails:null,
            loading:false
        }
        this.ModalInquiry = this.ModalInquiry.bind(this);
        this.closeModal = this.closeModal.bind(this);
        //console.log(this.props,"PackageDetails");
    }

    componentDidMount() {
        this.setState({
            package_details: this.props.package_details
        })
    }

    componentWillReceiveProps(nextProps) {

        this.setState({
            sourceInquiryDetails:nextProps.sourceInquiryDetails,
            ModalInquiry:false,
            loading:false
        });

    }



    InquirySubmit = ()=>{

        const {UserName, UserMobile, UserEmail, date, UserHours, UserMinutes,city,State} = this.state;


        let message ='abc';
        if(UserName == ''){
            message = 'Enter your name!.';
        }else if(UserMobile == ''){
            message = 'Enter your mobile number!.';
        }else if(UserMobile.length != 10){
            message = 'Mobile number is 10 digit number!.';
        }else if(UserEmail == ''){
            message = 'Enter your email address!.';
        }else if(!UserEmail || !isString(UserEmail) || (!/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/g.test(UserEmail))) {
            message = 'Enter valid email address!.';
        }else if(city == ''){
            message = 'Enter you city!.';
        }else if(State == ''){
            message = 'Enter you state!.';
        }else{
            this.setState({
                loading: true
            })

            let UserDetail = Auth.user();

            let amount = this.state.package_details.select_price ? this.state.package_details.select_price : this.props.package_details.price_from
            let namount = amount ? amount : 0
      
            let sendData={
                gg_holiday_package_id:this.props.package_details.id,
                user_name:UserName,
                email:UserEmail,
                mobile:UserMobile,
                city:city,
                state:State
            }

            this.props.setPackageEnquiry(sendData);

            return true;
        }
        Toaster.create({
            position: Position.RIGHT,
        }).show({message: message, intent: Intent.DANGER});
    }



    ModalInquiry() {

        /*if(!Auth.isLoggedIn()){

            Auth.login(true);
            return false;
        }*/
        let UserDetail = Auth.user();

        this.setState({
            UserName:UserDetail.fname,
            UserMobile:UserDetail.mobile,
            UserEmail:UserDetail.email,
            city:UserDetail.city,
            State:UserDetail.state,
            ModalInquiry: true
        });
    }
    closeModal() {this.setState({ModalInquiry: false});}

    render(){
        return(
            <React.Fragment>
                <Card interactive={false} elevation={Elevation.TWO} className="col-12">
                    <h3>Package Details</h3>

                    <div className="col-12">
                      
                      {
                        this.state.package_details ?
                          <React.Fragment>
                            <div className="row">
                              <div className="d-flex">

                                <img
                                  src={this.state.package_details.image}
                                  className="img-fluid hotel-img"
                                  onError={
                                      (e)=>{
                                          e.target.onerror = null; 
                                          e.target.src=defaultImage 
                                      }
                                  } 
                                  alt=""
                                />

                                <div className="ml-3">
                                  <h5>{this.state.package_details.package_name}</h5>
                                  {/*<h6>{this.state.package_details.day} Days</h6>*/}
                                </div>
                              </div>
                            </div>
                            <div className="row mt-4">
                                <div className="d-flex align-items-center w-100 details">
                                    <Icon icon="timeline-events" iconSize={20} />
                                    <div className="ml-3">
                                        <h6>DATE</h6>
                                        <p>Valid From {moment(this.state.package_details.start_date).format("DD MMMM YYYY")} - {moment(this.state.package_details.end_date).format("DD MMMM YYYY")}</p>
                                    </div>
                                </div>
                                {/*<div className="d-flex align-items-center w-100 details">
                                    <Icon icon="office" iconSize={20} />
                                    <div className="ml-3">
                                        <h6>duration</h6>
                                        <p>{this.state.package_details.night} NIGHTS / {this.state.package_details.day} DAYS</p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center w-100 details">
                                    <Icon icon="time" iconSize={20} />
                                    <div className="ml-3">
                                        <h6>holiday type</h6>
                                        <p>{this.state.package_details.holiday_type}</p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center w-100 details">
                                    <Icon icon="map-marker" iconSize={20} />
                                    <div className="ml-3">
                                        <h6>country</h6>
                                        <p>{this.state.package_details.country_name}</p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center w-100 details">
                                    <Icon icon="map-marker" iconSize={20} />
                                    <div className="ml-3">
                                        <h6>cities</h6>
                                        <p>{this.state.package_details.package_citie}</p>
                                    </div>
                                </div>*/}
                                <div className="w-100 details">
                                    <div className="d-flex align-items-center justify-content-between w-100">
                                          <h5>Starting From</h5>
                                          <h3>
                                            {
                                                Helper.formatMoney(this.state.package_details.price)
                                            }
                                          </h3>
                                    </div>
                                    {/*<div className="d-flex justify-content-end w-100 mt-3">
                                        <span className="Insc">({this.state.package_details.note}) <Icon icon="info-sign" iconSize={14} className="ml-2" /></span>
                                    </div>*/}
                                </div>
                                <div className="mt-4 w-100">
                                    <Button
                                        text="Enquire Now"
                                        intent={Intent.PRIMARY}
                                        onClick={this.ModalInquiry}
                                        className="d-block w-100 font-weight-light bp3-text-medium"
                                    />
                                </div>
                            </div>

                          </React.Fragment>
                        :
                        ''  
                      }
                    </div>
                </Card>

                <Dialog
                    title="Enquire Now"
                    className="bp3-dialog-large ModalInquiry"
                    isOpen={this.state.ModalInquiry}
                    onClose={this.closeModal}

                >
                    <div className={Classes.DIALOG_BODY}>
                       <div className="row">
                           <div className="col-12">
                               <div className="bp3-input-group">
                                   <input
                                       type="text"
                                       value={this.state.UserName}
                                       className="bp3-input"
                                       required
                                       onChange={(e)=>{
                                           this.setState({
                                               UserName:e.target.value
                                           })
                                       }}
                                   />
                                   <label className="form-control-placeholder">Name</label>
                               </div>
                               <div className="mt-4">
                                   <div className="bp3-input-group">
                                       <input
                                           type="number"
                                           value={this.state.UserMobile}
                                           className="bp3-input"
                                           required
                                           onChange={(e)=>{
                                               this.setState({
                                                   UserMobile:e.target.value
                                               })
                                           }}
                                       />
                                       <label className="form-control-placeholder">Mobile Number</label>
                                   </div>
                               </div>
                               <div className="mt-4">
                                   <div className="bp3-input-group">
                                       <input
                                           type="text"
                                           value={this.state.UserEmail}
                                           className="bp3-input"
                                           required
                                           onChange={(e)=>{
                                               this.setState({
                                                   UserEmail:e.target.value
                                               })
                                           }}
                                       />
                                       <label className="form-control-placeholder">Email Address</label>
                                   </div>
                               </div>
                               <div className="mt-4">
                                   <div className="bp3-input-group">
                                       <input
                                           type="text"
                                           value={this.state.city}
                                           className="bp3-input"
                                           required
                                           onChange={(e)=>{
                                               this.setState({
                                                   city:e.target.value
                                               })
                                           }}
                                       />
                                       <label className="form-control-placeholder">City</label>
                                   </div>
                               </div>
                               <div className="mt-4">
                                   <div className="bp3-input-group">
                                       <input
                                           type="text"
                                           value={this.state.State}
                                           className="bp3-input"
                                           required
                                           onChange={(e)=>{
                                               this.setState({
                                                   State:e.target.value
                                               })
                                           }}
                                       />
                                       <label className="form-control-placeholder">State</label>
                                   </div>
                               </div>
                               <div className="mt-5 mt-s-4 text-center">
                                   <Button
                                       loading={this.state.loading}
                                       type={"submit"}
                                       text={"Submit"}
                                       intent={Intent.PRIMARY}
                                       className="bp3-large col-md-6 col-12"
                                       onClick={()=>{this.InquirySubmit()}}
                                   />
                               </div>
                           </div>
                       </div>
                    </div>
                </Dialog>
            </React.Fragment>
        )
    }
}