import React, { Component } from 'react';
import { } from "@blueprintjs/core";
import defaultImage from "../../core/images/package_detail.jpg";

export default class TourDays extends React.Component{

    constructor(){
        super();
        this.state = {
            ByCategory:null
        }
    }

    componentDidMount(){
        
        this.setState({
                ByCategory:this.props.sourcePackageList.ByCategory
            }
        );
    }

    render(){
        return(
            <React.Fragment>
                {
                    this.state.ByCategory ?
                    <React.Fragment>

                        <div className="row">

                            {
                                this.state.ByCategory.map((data,i)=>{

                                    if(i > 1){
                                        return(
                                            <React.Fragment>

                                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 mb-4">
                                                    <div className="box">
                                                        <a href="" className="country-part" onClick = { () => this.props.SearchTours(data.AdvanceSearchPostData) }>
                                                            <img src={data.ImageURL} 
                                                                onError={
                                                                  (e)=>{
                                                                      e.target.onerror = null; 
                                                                      e.target.src=defaultImage 
                                                                  }
                                                              }
                                                            className="img-fluid" alt={data.Title} />
                                                            <div className="overlay">
                                                                <h2 className="tour-name">
                                                                    {data.Title}
                                                                </h2>
                                                                <h4 className="tour-days">
                                                                    ( {data.Count} Tours ) 
                                                                </h4>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>

                                            </React.Fragment>
                                        )    
                                    }
                                })
                            }
                        </div>

                    </React.Fragment>
                    :
                    ''    
                }    
            </React.Fragment>
        )
    }
}
