import React from 'react';
import ReactDOM from 'react-dom';
import {packageConfigure} from './Config';
import {PackageConfigure, GgUtils} from 'gg-react-utilities';
import App from './App';
import Config from './Config';
import { unregister as unregisterServiceWorker } from './serviceWorker';
import './index.css';


if(Config.ENV === "PRODUCTION") {
   GgUtils.sentryScripts(Config.REACT_APP_SENTRY_URL);
   GgUtils.googleAnalyticalScripts(Config.REACT_APP_GOOGLE_ANALYTICAL_CODE);
}


ReactDOM.render(<App />, document.getElementById('root'));

unregisterServiceWorker();
